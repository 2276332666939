import GridDataContainer from "../../components/GridData/GridDataContainer";
import GridDataItem from "../../components/GridData/GridDataItem";
import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import CustomButton from "../../components/CustomButton";
import CustomPaginationButton from "src/components/PaginationButton";
import { useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";
import { convertStringToYYYYMMDDWithDot } from "../../util/date-utils";
import ListScaffold from "../../components/List/Scaffold";
import { makeGridCol } from "../../util/grid-col-def-utils";
import SelectBox from "../../components/SelectBox";
import useTeacherDiffSelect from "../../hooks/use-teacher-diff-select";
import useClassDiffSelect from "../../hooks/use-class-diff-select";
import AcademyChildrenApi from "../../api/academy-children-api";
import { AcademyChildrenListModel } from "../../models/academy-children/academy-children-list-model";
import ClassApi from "../../api/class-api";

const Children = () => {
  ////////////////////////////////////////////////////////
  // State 및 핸들러, 관련 변수 정의
  ////////////////////////////////////////////////////////

  const navigator = useNavigate();

  const [childrenListData, setChildrenListData] = useState<AcademyChildrenListModel[]>(); // 유저 데이터
  const [totalRowCount, setTotalRowCount] = useState(0); // 전체 행 개수
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  const [isLoading, setIsLoading] = useState(false); // 로딩 상태

  /**
   * 필터
   */
  const searchKeywordRef = useRef(null); // 키워드 검색 ref
  const [teacherDiff, setTeacherDiff] = useState<string | number>('전체'); // 선생 구분 필터
  const [classDiff, setClassDiff] = useState('전체'); // 수업 구분 필터

  const [teacherDiffSelectList] = useTeacherDiffSelect();
  const [classDiffSelectList, setClassDiffSelectList] = useClassDiffSelect();

  // 선생님 구분을 핸들링한다.
  const handleTeacherChange = (event) => {
    setTeacherDiff(event.target.value);
  };

  // 수업 구분을 핸들링한다.
  const handleClassChange = (event) => {
    setClassDiff(event.target.value);
  };

  // 검색 버튼 클릭 시 핸들링한다.
  const handleSearchBtnClick = () => {
    try {
      setCurrentPage(1);
      fetchData({
        page: 1,
        keyword: searchKeywordRef.current.value,
        teacherId: teacherDiff === '전체' ? null : teacherDiff as number,
        classId: classDiff === '전체' ? null : parseInt(classDiff),
      });
    } catch (err) {
      console.log(err);
    }
  }

  // 초기화 버튼 클릭 시 핸들러
  // const apiRef = useGridApiContext();
  const handleResetBtnClick = () => {
    // 데이터를 모두 초기화
    setCurrentPage(1);
    searchKeywordRef.current.value = '';
    setTeacherDiff('전체');
    setClassDiff('전체');
    fetchData({ page: 1 });
  }

  // 만일, 선생님 셀렉트가 변경되었을 경우, 해당 선생님에 속한 수업 리스트를 불러온다.
  useEffect(() => {
    const fetchClassDataOfTeacher = async (params: { isReset: boolean }) => {
      try {
        // 수업 리스트를 불러온다.
        const classList = await ClassApi.list({
          academyId: parseInt(localStorage.getItem('academyId')),
          teacherId: params.isReset ? null : teacherDiff as number,
        }).then(value => value.data.data);

        const classObjList = [];
        classObjList.push({
          value: '전체',
          label: '전체',
        });
        for (const eachClass of classList) {
          classObjList.push({
            value: eachClass.id,
            label: eachClass.name,
          });
        }

        setClassDiffSelectList(classObjList);
      } catch (err) {
        console.log(err);
        alert('수업을 불러오는 중 오류가 발생했습니다.');
      }
    }

    if (teacherDiff !== '전체') {
      fetchClassDataOfTeacher({ isReset: false });
    } else {
      fetchClassDataOfTeacher({ isReset: true });
    }
  }, [teacherDiff]);

  ////////////////////////////////////////////////////////
  // 데이터 전처리 및 관련 로직 정의
  ////////////////////////////////////////////////////////
  const fetchData = async (queries: {
    page: number,
    keyword?: string,
    teacherId?: number,
    classId?: number,
  }) => {
    try {
      setIsLoading(true);

      const response = await AcademyChildrenApi.list({
        page: queries.page,
        academyId: parseInt(localStorage.getItem('academyId')),
        teacherId: queries.teacherId,
        classId: queries.classId,
        search: queries.keyword,
      });
      const { data, meta } = response.data;

      setChildrenListData(data);
      setTotalRowCount(meta['totalCount']);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  }

  // 초기 데이터 불러오기
  useEffect(() => {
    fetchData({
      page: currentPage,
      keyword: searchKeywordRef.current.value,
      teacherId: teacherDiff === '전체' ? null : teacherDiff as number,
      classId: classDiff === '전체' ? null : parseInt(classDiff),
    });
  }, [currentPage]);

  let rows = [];
  if (childrenListData) {
    rows = childrenListData.map(eachChild => {
      return {
        ...eachChild,
        createdAt: convertStringToYYYYMMDDWithDot(eachChild.createdAt),
      };
    });
  }

  const columns: GridColDef[] = [
    makeGridCol({ field: 'createdAt', headerName: '등록 날짜' }),
    makeGridCol({ field: 'name', headerName: '자녀명', width: 200 }),
    makeGridCol({ field: 'childrenTel', headerName: '자녀 연락처', width: 200 }),
    makeGridCol({ field: 'parentName', headerName: '학부모 명' }),
    makeGridCol({ field: 'tel', headerName: '학부모 연락처' }),
    makeGridCol({ field: 'classes', headerName: '수업' }),
    makeGridCol({
      field: '상세',
      headerName: '상세',
      renderCell: params => {
        return <CustomButton text={ '상세' } onClick={ () => {
          const { id: childrenId } = params;
          navigator({
            pathname: '/children/detail',
            search: `?${ createSearchParams({
              childrenId: childrenId.toString(),
            }) }`
          });
        } }/>
      }
    }),
  ];

  return (
    <ListScaffold
      title={ '원생 관리' }
      showRegisterBtn={ true }
      filterBuilder={
        <GridDataContainer colCount={ 2 } rowCount={ 2 }>
          <GridDataItem title={ '선생님 구분' } content={
            <SelectBox
              label={ '선생님 구분' }
              value={ teacherDiff }
              onChange={ handleTeacherChange }
              itemList={ teacherDiffSelectList }
            />
          }/>
          {/*<GridDataItem*/}
          {/*  title={ '수업' }*/}
          {/*  content={*/}
          {/*    inputs.teacherId !== '' ?*/}
          {/*      <SelectBox*/}
          {/*        label={ '수업 선택' }*/}
          {/*        value={ classDiff }*/}
          {/*        onChange={ handleClassChange }*/}
          {/*        itemList={ classDiffSelectList as any[] }*/}
          {/*      /> : <span>선생님을 선택해주세요.</span>*/}
          {/*  }/>*/}
          <GridDataItem
            title={ '수업 구분' }
            content={
              <SelectBox
                label={ '수업 구분' }
                value={ classDiff }
                onChange={ handleClassChange }
                itemList={ classDiffSelectList as any[] }
              />
            }
          />
          <GridDataItem
            title={ '검색' }
            content={
              <TextField
                className={ 'lg:w-[500px] w-80' }
                inputRef={ searchKeywordRef }
                name={ 'keyword' }
                label='학부모명, 자녀명으로 검색'
                size={ 'small' }
              />
            }
            colSpan={ 2 }
          />
        </GridDataContainer>
      }
      bodyBuilder={
        <DataGrid
          className={ '!focus:outline-none' }
          pagination
          paginationMode={ 'server' }
          loading={ isLoading }
          rowCount={ totalRowCount }
          rows={ rows }
          columns={ columns }
          pageSize={ 15 }
          onPageChange={ (page, _) => {
            // 페이지는 0부터 시작하므로 값을 보정한다.
            let revisedPage = page + 1;
            setCurrentPage(revisedPage);
          } }
          autoHeight={ true }
          disableColumnMenu={ true }
          components={ {
            Pagination: CustomPaginationButton,
          } }
          disableSelectionOnClick={ true }
          page={ currentPage - 1 }
        />
      }
      handleSearchBtnClick={ handleSearchBtnClick }
      handleResetBtnClick={ handleResetBtnClick }
      handleRegisterBtnClick={ () => {
        navigator('/children/create');
      } }
    />
  );
}

export default Children;
