import { useContext } from 'react';

import { alpha, Box, Button, List, ListItem, ListSubheader, styled } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { AssessmentTwoTone, PermIdentityTwoTone, } from '@mui/icons-material';
import { GiTeacher } from 'react-icons/gi';
import { MdClass, MdAttachMoney, MdPayment } from 'react-icons/md';
import { AiOutlineNotification } from 'react-icons/ai';
import { BsBook } from 'react-icons/bs';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${ theme.spacing(1) };

    & > .MuiList-root {
      padding: 0 ${ theme.spacing(0) } ${ theme.spacing(1) };
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${ theme.typography.pxToRem(12) };
      color: ${ theme.colors.alpha.black[50] };    
      padding: ${ theme.spacing(0, 2.5) };
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${ theme.spacing(3.2) };

          .MuiBadge-standard {
            background: ${ theme.colors.primary.main };
            font-size: ${ theme.typography.pxToRem(10) };
            font-weight: bold;
            text-transform: uppercase;
            color: ${ theme.palette.primary.contrastText };
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${ theme.colors.alpha.black[70] };
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${ theme.spacing(1.2, 3) };

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${ theme.transitions.create(['color']) };

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${ theme.colors.alpha.black[30] };
            font-size: ${ theme.typography.pxToRem(20) };
            margin-right: ${ theme.spacing(1) };
          }
          
          .MuiButton-endIcon {
            color: ${ theme.colors.alpha.trueWhite[50] };
            margin-left: auto;
            opacity: .8;
            font-size: ${ theme.typography.pxToRem(20) };
          }

          &.active,
          &:hover {
            background-color: ${ alpha(theme.colors.alpha.black[100], 0.06) };
            color: ${ theme.colors.alpha.black[100] };

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${ theme.colors.alpha.black[100] };
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${ theme.spacing(7) };
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${ theme.spacing(1, 0) };
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${ theme.spacing(0.8, 3) };

              .MuiBadge-root {
                right: ${ theme.spacing(3.2) };
              }

              &:before {
                content: ' ';
                background: ${ theme.colors.alpha.trueWhite[100] };
                opacity: 0;
                transition: ${ theme.transitions.create([
    'transform',
    'opacity'
  ]) };
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${ theme.spacing(1.8) };
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);

  return (
    <>
      <MenuWrapper>
        <List component="div">
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={ RouterLink }
                  onClick={ closeSidebar }
                  to="/dashboard"
                  startIcon={ <AssessmentTwoTone/> }
                >
                  대시보드
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        <List
          component={ 'div' }
          subheader={
            <ListSubheader component={ 'div' } disableSticky>
              학원관리
            </ListSubheader>
          }>
          <SubMenuWrapper>
            <List component={ 'div' }>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={ RouterLink }
                  onClick={ closeSidebar }
                  to="/children"
                  startIcon={ <PermIdentityTwoTone/> }
                >
                  원생 관리
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={ RouterLink }
                  onClick={ closeSidebar }
                  to="/teacher"
                  startIcon={ <GiTeacher /> }
                >
                  선생님 관리
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={ RouterLink }
                  onClick={ closeSidebar }
                  to="/class"
                  startIcon={ <MdClass /> }
                >
                  수업 관리
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        <List
          component={ 'div' }
          subheader={
            <ListSubheader component={ 'div' } disableSticky>
              게시글 관리
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component={ 'div' }>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={ RouterLink }
                  onClick={ closeSidebar }
                  to="/academy-notice"
                  startIcon={ <AiOutlineNotification /> }
                >
                  학원 공지
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={ RouterLink }
                  onClick={ closeSidebar }
                  to="/class-notice"
                  startIcon={ <AiOutlineNotification /> }
                >
                  수업 공지
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={ RouterLink }
                  onClick={ closeSidebar }
                  to="/class-content"
                  startIcon={ <BsBook /> }
                >
                  수업 내용
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        <List
          component={ 'div' }
          subheader={
            <ListSubheader component={ 'div' } disableSticky>
              매출 관리
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component={ 'div' }>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={ RouterLink }
                  onClick={ closeSidebar }
                  to="/dues"
                  startIcon={ <MdAttachMoney /> }
                >
                  원비 관리
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={ RouterLink }
                  onClick={ closeSidebar }
                  to="/calculate"
                  startIcon={ <MdPayment /> }
                >
                  정산 관리
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>


        {/*<List*/ }
        {/*    component="div"*/ }
        {/*    subheader={*/ }
        {/*        <ListSubheader component="div" disableSticky>*/ }
        {/*            Dashboards*/ }
        {/*        </ListSubheader>*/ }
        {/*    }*/ }
        {/*>*/ }
        {/*    <SubMenuWrapper>*/ }
        {/*        <List component="div">*/ }
        {/*            <ListItem component="div">*/ }
        {/*                <Button*/ }
        {/*                    disableRipple*/ }
        {/*                    component={RouterLink}*/ }
        {/*                    onClick={closeSidebar}*/ }
        {/*                    to="/dashboards/crypto"*/ }
        {/*                    startIcon={<BrightnessLowTwoToneIcon/>}*/ }
        {/*                >*/ }
        {/*                    Cryptocurrency*/ }
        {/*                </Button>*/ }
        {/*            </ListItem>*/ }
        {/*            <ListItem component="div">*/ }
        {/*                <Button*/ }
        {/*                    disableRipple*/ }
        {/*                    component={RouterLink}*/ }
        {/*                    onClick={closeSidebar}*/ }
        {/*                    to="/dashboards/messenger"*/ }
        {/*                    startIcon={<MmsTwoToneIcon/>}*/ }
        {/*                >*/ }
        {/*                    Messenger*/ }
        {/*                </Button>*/ }
        {/*            </ListItem>*/ }
        {/*        </List>*/ }
        {/*    </SubMenuWrapper>*/ }
        {/*</List>*/ }
        {/*<List*/ }
        {/*    component="div"*/ }
        {/*    subheader={*/ }
        {/*        <ListSubheader component="div" disableSticky>*/ }
        {/*            Management*/ }
        {/*        </ListSubheader>*/ }
        {/*    }*/ }
        {/*>*/ }
        {/*    <SubMenuWrapper>*/ }
        {/*        <List component="div">*/ }
        {/*            <ListItem component="div">*/ }
        {/*                <Button*/ }
        {/*                    disableRipple*/ }
        {/*                    component={RouterLink}*/ }
        {/*                    onClick={closeSidebar}*/ }
        {/*                    to="/management/transactions"*/ }
        {/*                    startIcon={<TableChartTwoToneIcon/>}*/ }
        {/*                >*/ }
        {/*                    Transactions List*/ }
        {/*                </Button>*/ }
        {/*            </ListItem>*/ }
        {/*        </List>*/ }
        {/*    </SubMenuWrapper>*/ }
        {/*</List>*/ }
        {/*<List*/ }
        {/*    component="div"*/ }
        {/*    subheader={*/ }
        {/*        <ListSubheader component="div" disableSticky>*/ }
        {/*            Accounts*/ }
        {/*        </ListSubheader>*/ }
        {/*    }*/ }
        {/*>*/ }
        {/*    <SubMenuWrapper>*/ }
        {/*        <List component="div">*/ }
        {/*            <ListItem component="div">*/ }
        {/*                <Button*/ }
        {/*                    disableRipple*/ }
        {/*                    component={RouterLink}*/ }
        {/*                    onClick={closeSidebar}*/ }
        {/*                    to="/management/profile/details"*/ }
        {/*                    startIcon={<AccountCircleTwoToneIcon/>}*/ }
        {/*                >*/ }
        {/*                    User Profile*/ }
        {/*                </Button>*/ }
        {/*            </ListItem>*/ }
        {/*            <ListItem component="div">*/ }
        {/*                <Button*/ }
        {/*                    disableRipple*/ }
        {/*                    component={RouterLink}*/ }
        {/*                    onClick={closeSidebar}*/ }
        {/*                    to="/management/profile/settings"*/ }
        {/*                    startIcon={<DisplaySettingsTwoToneIcon/>}*/ }
        {/*                >*/ }
        {/*                    Account Settings*/ }
        {/*                </Button>*/ }
        {/*            </ListItem>*/ }
        {/*        </List>*/ }
        {/*    </SubMenuWrapper>*/ }
        {/*</List>*/ }
        {/*<List*/ }
        {/*    component="div"*/ }
        {/*    subheader={*/ }
        {/*        <ListSubheader component="div" disableSticky>*/ }
        {/*            Components*/ }
        {/*        </ListSubheader>*/ }
        {/*    }*/ }
        {/*>*/ }
        {/*    <SubMenuWrapper>*/ }
        {/*        <List component="div">*/ }
        {/*            <ListItem component="div">*/ }
        {/*                <Button*/ }
        {/*                    disableRipple*/ }
        {/*                    component={RouterLink}*/ }
        {/*                    onClick={closeSidebar}*/ }
        {/*                    to="/components/buttons"*/ }
        {/*                    startIcon={<BallotTwoToneIcon/>}*/ }
        {/*                >*/ }
        {/*                    Buttons*/ }
        {/*                </Button>*/ }
        {/*            </ListItem>*/ }
        {/*            <ListItem component="div">*/ }
        {/*                <Button*/ }
        {/*                    disableRipple*/ }
        {/*                    component={RouterLink}*/ }
        {/*                    onClick={closeSidebar}*/ }
        {/*                    to="/components/modals"*/ }
        {/*                    startIcon={<BeachAccessTwoToneIcon/>}*/ }
        {/*                >*/ }
        {/*                    Modals*/ }
        {/*                </Button>*/ }
        {/*            </ListItem>*/ }
        {/*            <ListItem component="div">*/ }
        {/*                <Button*/ }
        {/*                    disableRipple*/ }
        {/*                    component={RouterLink}*/ }
        {/*                    onClick={closeSidebar}*/ }
        {/*                    to="/components/accordions"*/ }
        {/*                    startIcon={<EmojiEventsTwoToneIcon/>}*/ }
        {/*                >*/ }
        {/*                    Accordions*/ }
        {/*                </Button>*/ }
        {/*            </ListItem>*/ }
        {/*            <ListItem component="div">*/ }
        {/*                <Button*/ }
        {/*                    disableRipple*/ }
        {/*                    component={RouterLink}*/ }
        {/*                    onClick={closeSidebar}*/ }
        {/*                    to="/components/tabs"*/ }
        {/*                    startIcon={<FilterVintageTwoToneIcon/>}*/ }
        {/*                >*/ }
        {/*                    Tabs*/ }
        {/*                </Button>*/ }
        {/*            </ListItem>*/ }
        {/*            <ListItem component="div">*/ }
        {/*                <Button*/ }
        {/*                    disableRipple*/ }
        {/*                    component={RouterLink}*/ }
        {/*                    onClick={closeSidebar}*/ }
        {/*                    to="/components/badges"*/ }
        {/*                    startIcon={<HowToVoteTwoToneIcon/>}*/ }
        {/*                >*/ }
        {/*                    Badges*/ }
        {/*                </Button>*/ }
        {/*            </ListItem>*/ }
        {/*            <ListItem component="div">*/ }
        {/*                <Button*/ }
        {/*                    disableRipple*/ }
        {/*                    component={RouterLink}*/ }
        {/*                    onClick={closeSidebar}*/ }
        {/*                    to="/components/tooltips"*/ }
        {/*                    startIcon={<LocalPharmacyTwoToneIcon/>}*/ }
        {/*                >*/ }
        {/*                    Tooltips*/ }
        {/*                </Button>*/ }
        {/*            </ListItem>*/ }
        {/*            <ListItem component="div">*/ }
        {/*                <Button*/ }
        {/*                    disableRipple*/ }
        {/*                    component={RouterLink}*/ }
        {/*                    onClick={closeSidebar}*/ }
        {/*                    to="/components/avatars"*/ }
        {/*                    startIcon={<RedeemTwoToneIcon/>}*/ }
        {/*                >*/ }
        {/*                    Avatars*/ }
        {/*                </Button>*/ }
        {/*            </ListItem>*/ }
        {/*            <ListItem component="div">*/ }
        {/*                <Button*/ }
        {/*                    disableRipple*/ }
        {/*                    component={RouterLink}*/ }
        {/*                    onClick={closeSidebar}*/ }
        {/*                    to="/components/cards"*/ }
        {/*                    startIcon={<SettingsTwoToneIcon/>}*/ }
        {/*                >*/ }
        {/*                    Cards*/ }
        {/*                </Button>*/ }
        {/*            </ListItem>*/ }
        {/*            <ListItem component="div">*/ }
        {/*                <Button*/ }
        {/*                    disableRipple*/ }
        {/*                    component={RouterLink}*/ }
        {/*                    onClick={closeSidebar}*/ }
        {/*                    to="/components/forms"*/ }
        {/*                    startIcon={<TrafficTwoToneIcon/>}*/ }
        {/*                >*/ }
        {/*                    Forms*/ }
        {/*                </Button>*/ }
        {/*            </ListItem>*/ }
        {/*        </List>*/ }
        {/*    </SubMenuWrapper>*/ }
        {/*</List>*/ }
        {/*<List*/ }
        {/*    component="div"*/ }
        {/*    subheader={*/ }
        {/*        <ListSubheader component="div" disableSticky>*/ }
        {/*            Extra Pages*/ }
        {/*        </ListSubheader>*/ }
        {/*    }*/ }
        {/*>*/ }
        {/*    <SubMenuWrapper>*/ }
        {/*        <List component="div">*/ }
        {/*            <ListItem component="div">*/ }
        {/*                <Button*/ }
        {/*                    disableRipple*/ }
        {/*                    component={RouterLink}*/ }
        {/*                    onClick={closeSidebar}*/ }
        {/*                    to="/status/404"*/ }
        {/*                    startIcon={<CheckBoxTwoToneIcon/>}*/ }
        {/*                >*/ }
        {/*                    Error 404*/ }
        {/*                </Button>*/ }
        {/*            </ListItem>*/ }
        {/*            <ListItem component="div">*/ }
        {/*                <Button*/ }
        {/*                    disableRipple*/ }
        {/*                    component={RouterLink}*/ }
        {/*                    onClick={closeSidebar}*/ }
        {/*                    to="/status/500"*/ }
        {/*                    startIcon={<CameraFrontTwoToneIcon/>}*/ }
        {/*                >*/ }
        {/*                    Error 500*/ }
        {/*                </Button>*/ }
        {/*            </ListItem>*/ }
        {/*            <ListItem component="div">*/ }
        {/*                <Button*/ }
        {/*                    disableRipple*/ }
        {/*                    component={RouterLink}*/ }
        {/*                    onClick={closeSidebar}*/ }
        {/*                    to="/status/status/coming-soon"*/ }
        {/*                    startIcon={<ChromeReaderModeTwoToneIcon/>}*/ }
        {/*                >*/ }
        {/*                    Coming Soon*/ }
        {/*                </Button>*/ }
        {/*            </ListItem>*/ }
        {/*            <ListItem component="div">*/ }
        {/*                <Button*/ }
        {/*                    disableRipple*/ }
        {/*                    component={RouterLink}*/ }
        {/*                    onClick={closeSidebar}*/ }
        {/*                    to="/status/maintenance"*/ }
        {/*                    startIcon={<WorkspacePremiumTwoToneIcon/>}*/ }
        {/*                >*/ }
        {/*                    Maintenance*/ }
        {/*                </Button>*/ }
        {/*            </ListItem>*/ }
        {/*        </List>*/ }
        {/*    </SubMenuWrapper>*/ }
        {/*</List>*/ }
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
