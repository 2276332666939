import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Class from "./content/class";
import ClassCreate from "./content/class/Create";
import ClassDetail from "./content/class/Detail";
import Teacher from "./content/teacher";
import TeacherCreate from "./content/teacher/Create";
import TeacherDetail from "./content/teacher/Detail";
import Children from "./content/children";
import ChildrenCreate from "./content/children/Create";
import ChildrenDetail from "./content/children/Detail";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// 로그인
const Login = Loader(lazy(() => import('src/content/auth/Login')));

// 대시보드
const Dashboard = Loader(lazy(() => import('src/content/dashboard')));

// 공지사항 리스트
const Notice = Loader(lazy(() => import('src/content/notice')));

// 공지사항 등록
const NoticeCreate = Loader(lazy(() => import('src/content/notice/Create')));

// 공지사항 상세
const NoticeDetail = Loader(lazy(() => import('src/content/notice/Detail')));

// 학원 공지사항 리스트
const AcademyNotice = Loader(lazy(() => import('src/content/academy-notice')));

// 학원 공지사항 등록
const AcademyNoticeCreate = Loader(lazy(() => import('src/content/academy-notice/Create')));

// 학원 공지사항 상세
const AcademyNoticeDetail = Loader(lazy(() => import('src/content/academy-notice/Detail')));

// 수업 공지사항 리스트
const ClassNotice = Loader(lazy(() => import('src/content/class-notice')));

// 수업 공지사항 등록
const ClassNoticeCreate = Loader(lazy(() => import('src/content/class-notice/Create')));

// 수업 공지사항 상세
const ClassNoticeDetail = Loader(lazy(() => import('src/content/class-notice/Detail')));

// 수업 내용 리스트
const ClassContent = Loader(lazy(() => import('src/content/class-content')));

// 수업 내용 등록
const ClassContentCreate = Loader(lazy(() => import('src/content/class-content/Create')));

// 수업 내용 상세
const ClassContentDetail = Loader(lazy(() => import('src/content/class-content/Detail')));

// 원비 리스트
const Dues = Loader(lazy(() => import('src/content/dues')));

// 정산 리스트
const Calculate = Loader(lazy(() => import('src/content/calculate')));
const CalculateDetail = Loader(lazy(() => import('src/content/calculate/Detail')));

// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <Navigate to={'auth/login'} />
      },
      // auth (인증)
      {
        path: 'auth',
        children: [
          {
            path: '',
            element: <Navigate to={'login'}/>
          },
          {
            path: 'login',
            element: <Login />
          },
        ]
      },
      {
        path: 'status',
        element: <SidebarLayout />,
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  // 수업관리
  {
    path: 'class',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Class />
      },
      {
        path: 'create',
        element: <ClassCreate />
      },
      {
        path: 'detail',
        element: <ClassDetail />
      }
    ]
  },

  // 선생님 관리
  {
    path: 'teacher',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Teacher />
      },
      {
        path: 'create',
        element: <TeacherCreate />
      },
      {
        path: 'detail',
        element: <TeacherDetail />
      }
    ]
  },

  // 원생 관리
  {
    path: 'children',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Children />
      },
      {
        path: 'create',
        element: <ChildrenCreate />
      },
      {
        path: 'detail',
        element: <ChildrenDetail />
      }
    ]
  },
  // 공지사항
  {
    path: 'notice',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Notice />
      },
      {
        path: 'detail',
        element: <NoticeDetail />
      },
      {
        path: 'register',
        element: <NoticeCreate />
      }
    ]
  },
  // 학원 공지사항
  {
    path: 'academy-notice',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <AcademyNotice />
      },
      {
        path: 'register',
        element: <AcademyNoticeCreate/>
      },
      {
        path: 'detail',
        element: <AcademyNoticeDetail />
      }
    ]
  },
  // 수업 공지사항
  {
    path: 'class-notice',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <ClassNotice />
      },
      {
        path: 'register',
        element: <ClassNoticeCreate/>
      },
      {
        path: 'detail',
        element: <ClassNoticeDetail />
      }
    ]
  },
  // 수업 내용
  {
    path: 'class-content',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <ClassContent />
      },
      {
        path: 'create',
        element: <ClassContentCreate />
      },
      {
        path: 'detail',
        element: <ClassContentDetail />
      }
    ]
  },
  // 원비 관리
  {
    path: 'dues',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Dues />
      }
    ]
  },
  // 정산 관리
  {
    path: 'calculate',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Calculate />
      },
      {
        path: 'detail',
        element: <CalculateDetail />
      }
    ]
  },
  {
    path: 'dashboard',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Dashboard />
      },
    ]
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  }
];

export default routes;
