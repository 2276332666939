import GridDataContainer from "../../components/GridData/GridDataContainer";
import GridDataItem from "../../components/GridData/GridDataItem";
import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import { GridColDef } from "@mui/x-data-grid";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";
import { convertStringToYYYYMMDDWithDot } from "../../util/date-utils";
import ClassApi from "../../api/class-api";
import { ClassListModel } from "../../models/class/class-list-model";
import { makeGridCol } from "../../util/grid-col-def-utils";
import CustomDataGrid from "../../components/GridData/CustomDataGrid";
import ListScaffold from "../../components/List/Scaffold";
import useTeacherDiffSelect from "../../hooks/use-teacher-diff-select";
import SelectBox from "../../components/SelectBox";
import { calcOrderNumber } from "../../util/common-utils";

const Class = () => {
  ////////////////////////////////////////////////////////
  // State 및 핸들러, 관련 변수 정의
  ////////////////////////////////////////////////////////

  const navigator = useNavigate();

  const [classListData, setClassListData] = useState<ClassListModel[]>(); // 수업 데이터
  const [totalRowCount, setTotalRowCount] = useState(0); // 전체 데이터 개수
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  const [isLoading, setIsLoading] = useState(false); // 로딩 상태
  const [orderList, setOrderList] = useState<number[]>([]); // 순번

  /**
   * 필터
   */
  const searchKeywordRef = useRef(null);
  const [teacherDiff, setTeacherDiff] = useState('전체'); // 선생 구분 필터

  const [teacherDiffSelectList] = useTeacherDiffSelect();

  // 선생님 구분을 핸들링한다.
  const handleTeacherChange = (event) => {
    setTeacherDiff(event.target.value);
  };

  // 검색 버튼 클릭 시 핸들링한다.
  const handleSearchBtnClick = () => {
    try {
      setCurrentPage(1);
      fetchData({
        page: 1,
        keyword: searchKeywordRef.current.value,
        teacherId: teacherDiff === '전체' ? null : parseInt(teacherDiff)
      });
    } catch (err) {
      console.log(err);
    }
  }

  // 초기화 버튼 클릭 시 핸들러
  // const apiRef = useGridApiContext();
  const handleResetBtnClick = () => {
    // 데이터를 모두 초기화
    setCurrentPage(1);
    setTeacherDiff('전체');
    searchKeywordRef.current.value = '';
    fetchData({ page: 1, keyword: '', teacherId: null });
  }

  ////////////////////////////////////////////////////////
  // 데이터 전처리 및 관련 로직 정의
  ////////////////////////////////////////////////////////
  const fetchData = async (queries: {
    page: number,
    keyword?: string,
    teacherId?: number,
  }) => {
    try {
      setIsLoading(true);

      const response = await ClassApi.list({
        page: queries.page,
        search: queries.keyword,
        teacherId: queries.teacherId,
        academyId: parseInt(localStorage.getItem('academyId')),
      });
      const { data, meta } = response.data;

      setClassListData(data);
      setTotalRowCount(meta['totalCount']);

      setOrderList(calcOrderNumber(meta.totalCount, currentPage, 15));
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  // 초기 데이터 불러오기
  useEffect(() => {
    fetchData({
      page: currentPage,
      keyword: searchKeywordRef.current.value,
      teacherId: teacherDiff === '전체' ? null : parseInt(teacherDiff)
    });
  }, [currentPage]);

  const columns: GridColDef[] = [
    makeGridCol({ field: 'order', headerName: '순번' }),
    makeGridCol({ field: 'createdAt', headerName: '등록 날짜' }),
    makeGridCol({ field: 'userName', headerName: '선생님', width: 200 }),
    makeGridCol({ field: 'name', headerName: '수업' }),
    makeGridCol({ field: 'userCount', headerName: '회원수' }),
    makeGridCol({
      field: '공지',
      headerName: '공지',
      renderCell: params => {
        return <CustomButton text={ '보기' } onClick={ () => {
          const { id: classId } = params;
          navigator({
            pathname: '/class-notice',
            search: `?${ createSearchParams({
              classId: classId.toString(),
            }) }`
          });
        } }/>
      }
    }),
    makeGridCol({
      field: '내용',
      headerName: '내용',
      renderCell: params => {
        return <CustomButton text={ '보기' } onClick={ () => {
          const { id: classId } = params;
          navigator({
            pathname: '/class-content',
            search: `?${ createSearchParams({
              classId: classId.toString(),
            }) }`
          });
        } }/>
      }
    }),
    makeGridCol({
      field: '상세',
      headerName: '상세',
      renderCell: params => {
        return <CustomButton text={ '상세' } onClick={ () => {
          const { id: classId } = params;
          navigator({
            pathname: '/class/detail',
            search: `?${ createSearchParams({
              classId: classId.toString(),
            }) }`
          });
        } }/>
      }
    }),
  ];

  let rows = [];
  if (classListData) {
    rows = classListData.map((eachUser, index) => {
      return {
        ...eachUser,
        order: orderList[index],
        createdAt: convertStringToYYYYMMDDWithDot(eachUser.createdAt),
      };
    });
  }

  return (
    <ListScaffold
      title={ '수업 관리' }
      filterBuilder={
        <GridDataContainer colCount={ 2 } rowCount={ 1 }>
          <GridDataItem title={ '선생님 구분' } content={
            <SelectBox label={ '선생님 구분' } value={ teacherDiff } onChange={ handleTeacherChange } itemList={ teacherDiffSelectList } />
          }/>
          <GridDataItem
            title={ '검색' }
            content={
              <TextField
                className={ 'lg:w-[500px] w-80' }
                name={ 'keyword' }
                inputRef={ searchKeywordRef }
                label='이름, 수업명으로 검색'
                size={ 'small' }
              />
            }
          />
        </GridDataContainer>
      }
      handleSearchBtnClick={ handleSearchBtnClick }
      handleResetBtnClick={ handleResetBtnClick }
      bodyBuilder={
        <CustomDataGrid
          isLoading={ isLoading }
          totalRowCount={ totalRowCount }
          rows={ rows }
          columns={ columns }
          onPageChange={ (page, _) => setCurrentPage(page) }
          page={ currentPage - 1 }
        />
      }
      showRegisterBtn={ true }
      handleRegisterBtnClick={ () => {
        navigator('/class/create');
      } }
    />
  );
}

export default Class;
