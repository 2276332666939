import { FC, ReactNode } from 'react';
import {Box, alpha, lighten, useTheme, Tooltip, IconButton} from '@mui/material';
import { Outlet } from 'react-router-dom';

import Sidebar from './Sidebar';
import Header from './Header';
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";

interface SidebarLayoutProps {
    children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {
    const theme = useTheme();

    return (
        <>
            <Box
                sx={{
                    flex: 1,
                    height: '100%',

                    '.MuiPageTitle-wrapper': {
                        background:
                            theme.palette.mode === 'dark'
                                ? theme.colors.alpha.trueWhite[5]
                                : theme.colors.alpha.white[50],
                        marginBottom: `${theme.spacing(4)}`,
                        boxShadow:
                            theme.palette.mode === 'dark'
                                ? `0 1px 0 ${alpha(
                                    lighten(theme.colors.primary.main, 0.7),
                                    0.15
                                )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                                : `0px 2px 4px -3px ${alpha(
                                    theme.colors.alpha.black[100],
                                    0.1
                                )}, 0px 5px 12px -4px ${alpha(
                                    theme.colors.alpha.black[100],
                                    0.05
                                )}`
                    }
                }}
            >
                <Box
                    component="span"
                    sx={{
                        display: { lg: 'none', xs: 'inline-block' }
                    }}
                >
                    <Header />
                </Box>
                <Sidebar/>
                <Box
                    sx={{
                        position: 'relative',
                        zIndex: 5,
                        display: 'block',
                        flex: 1,
                        pt: {
                            xs: `${theme.header.height}`,
                            md: '0px',
                        },
                        [theme.breakpoints.up('lg')]: {
                            ml: `${theme.sidebar.width}`
                        }
                    }}
                >
                    <Box display="block">
                        <Outlet/>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default SidebarLayout;
