import React, { FC, ReactNode } from 'react';

/**
 * color 은 색상 코드로 정의한다.
 * 만일, color 가 없을 경우 primary color 를 적용한다.
 */
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    color?: string;
    hoverColor?: string;
    activeColor?: string;
    text: string;
    height?: string;
    width?: string;
    bounceWhenEnabled?: boolean;
    children?: ReactNode;
}

const Button: FC<ButtonProps> = ({
        className,
        color,
        hoverColor,
        activeColor,
        text,
    height,
    width,
        bounceWhenEnabled,
        children,
        ...rest
    }) => {
    return (
        <>
            <button
                className={className == undefined ? `${height == undefined ? '' : height} ${ width == undefined ? '' : width } px-[22px] py-[12px] ${color == undefined ? 'bg-primary' : color} font-bold text-white rounded-lg ${hoverColor == undefined ? 'hover:bg-primary-hover' : hoverColor} ${activeColor == undefined ? 'active:bg-primary-active' : activeColor} flex flex-row items-center justify-center transition duration-150 disabled:bg-gray-300 ${bounceWhenEnabled == true ? 'enabled:animate-bounce' : ''}`
                    : className}
                {...rest}
            >
                {text}
            </button>
        </>
    );
}

export default Button;
