import dayjs, { Dayjs } from "dayjs";

/**
 * DayJS 형태의 객체를 YYYY.MM.DD. 형태로 변환한다.
 * @param date DayJS 객체
 */
export const convertDayJsToYYYYMMDDWithDot = (date: Dayjs) => {
    return date == null ? null : date.format('YYYY.MM.DD.');
}

/**
 * String 형태의 ISO8601 날짜 데이터를 YYYY.MM.DD. 형태로 변환한다.
 * @param dateStr 문자열 날짜
 */
export const convertStringToYYYYMMDDWithDot = (dateStr: string) => {
    return dayjs(Date.parse(dateStr)).format('YYYY.MM.DD.');
}

/**
 * DayJS 형태의 객체를 YYYY-MM-DD 형태로 변환한다.
 * @param date DayJS 객체
 */
export const convertDayJsToYYYYMMDDWithDash = (date: Dayjs): null | string => {
    return date == null ? null : date.format('YYYY-MM-DD');
}

/**
 * String 형태의 ISO8601 날짜 데이터를 YYYY.MM.DD. 형태로 변환한다.
 * @param dateStr 문자열 날짜
 */
export const convertStringToYYYYMMDDWithDash = (dateStr: string) => {
    return dayjs(Date.parse(dateStr)).format('YYYY-MM-DD');
}

export const convertStringToDayJs = (dateStr: string): Dayjs => {
    return dayjs(new Date(dateStr));
}

/**
 * String 형태의 날짜를 입력받아 현재 일자 기준으로 남은 일수를 계산한다.
 * @param date ISO8601 형태의 String Date
 */
export const calcLeftDaysFromString = (date: string): number => {
    const currentDate = dayjs().startOf('day');

    return dayjs(date).diff(currentDate) / 1000 / 60 / 60 / 24;
}

export const providerToKor = (provider: string) => {
    switch (provider) {
        case 'kakao':
            return '카카오';
        case '애플':
            return '애플';
        default:
            return '일반';
    }
}

export function paginate<T>(collection: T[], index: number, size: number): T[] {
    index = Math.max(0, index - 1);
    size = Math.max(1, size);
    const start = index * size;
    const end = (index + 1) * size;

    return collection.slice(start, end);
}