import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { LoadingSpinner } from "../../LoadingSpinner";
import React, {FC, ReactNode} from "react";

interface ConfirmDialogProps {
    isOpen: boolean;
    title: string | ReactNode;
    content: string | ReactNode;
    onCancelBtnClick: () => void;
    onConfirmBtnClick: () => void;
    // cancelBtnText?: string;
    confirmBtnText?: string;
    // cancelBtnColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    confirmBtnColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    isConfirmBtnLoading?: boolean;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

/**
 *
 * @param isOpen 다이얼로그 출력 여부
 * @param title 제목
 * @param content 내용
 * @param onConfirmBtnClick 확인 버튼 클릭 시 콜백
 * @param cancelBtnText 취소 버튼 텍스트
 * @param cancelBtnColor 취소 버튼 색상
 * @param onCancelBtnClick 취소 버튼 클릭 시 콜백
 * @param confirmBtnText 확인 버튼 텍스트
 * @param confirmBtnColor 확인 버튼 색상
 * @param isConfirmBtnLoading 확인버튼 클릭 시 Loading Indicator 출력 여부
 * @constructor
 */
const ConfirmDialog: FC<ConfirmDialogProps> = (
    {
        isOpen,
        title,
        content,
        onConfirmBtnClick,
        // cancelBtnText,
        // cancelBtnColor,
        onCancelBtnClick,
        confirmBtnText,
        confirmBtnColor,
        isConfirmBtnLoading,
        size = 'xs',
    }
) => {
    return (
        <Dialog
            open={isOpen}
            // onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={ size }
            fullWidth={true}
        >
            <DialogTitle id="alert-dialog-title">
              <div className={'flex flex-row justify-between'}>
                <span className={'text-lg font-bold'}>{ title }</span>
                <button className={'text-gray-500, font-bold'} onClick={ onCancelBtnClick }>X</button>
              </div>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {/*<Button onClick={onCancelBtnClick} color={!cancelBtnColor ? 'secondary' : cancelBtnColor}>{ !cancelBtnText ? '취소' : cancelBtnText }</Button>*/}
                {
                    isConfirmBtnLoading ?
                        <LoadingSpinner/>
                        : <Button onClick={ onConfirmBtnClick } color={!confirmBtnColor ? 'primary' : confirmBtnColor} autoFocus>
                            { !confirmBtnText ? '확인' : confirmBtnText }
                        </Button>
                }
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDialog;
