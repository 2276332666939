import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Button, MenuItem, TextField } from "@mui/material";
import DetailScaffold from "../../../components/Detail/Scaffold";
import GridDataContainer from "../../../components/GridData/GridDataContainer";
import GridDataItem from "../../../components/GridData/GridDataItem";
import _ from "lodash";
import ClassApi from "../../../api/class-api";
import TeacherApi from "../../../api/teacher-api";
import { AxiosError, HttpStatusCode } from "axios";
import { useNavigate } from "react-router";
import { createSearchParams, useSearchParams } from "react-router-dom";
import DimmerLoading from "../../../components/Dimmer/Loading";
import { GridColDef } from "@mui/x-data-grid";
import CustomButton from "../../../components/CustomButton";
import dayjs from "dayjs";
import { makeGridCol } from "../../../util/grid-col-def-utils";
import DetailTitle from "../../../components/Detail/Text/DetailTitle";
import CustomDataGrid from "../../../components/GridData/CustomDataGrid";
import WidgetDialog from "../../../components/Dialog/WidgetDialog";
import ChildrenApi from "../../../api/children-api";
import AcademyChildrenApi from "../../../api/academy-children-api";
import { AcademyChildrenListModel } from "../../../models/academy-children/academy-children-list-model";
import ClassUserApi from "../../../api/class-user-api";

const ClassDetail = () => {
  const navigator = useNavigate();

  const [queryParams, ignoreSetQueryParams] = useSearchParams();
  const classId = parseInt(queryParams.get('classId'));

  const [isOpenChildrenSearchDialog, setIsOpenChildrenSearchDialog] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isChildrenLoading, setIsChildrenLoading] = useState(false); // 원생의 페이징 로딩 여부
  const [totalChildrenRowCount, setTotalChildrenRowCount] = useState(0); // 원생 총 데이터 개수
  const [currentChildrenPage, setCurrentChildrenPage] = useState(1); // 현재 원생 테이블 페이지
  const [childrenList, setChildrenList] = useState<any>([]); // 원생 데이터

  /*
    원생 검색 시작
   */
  // 원생 검색 Ref
  const searchChildrenRef = useRef(null);
  const [selectedChildren, setSelectedChildren] = useState<number>(null);

  // 검색된 원생 State
  const [searchedChildrenList, setSearchedChildrenList] = useState<AcademyChildrenListModel[]>([]);

  const resetSearchedChildren = () => {
    searchChildrenRef.current.value = '';
    setSelectedChildren(null);
    setSearchedChildrenList([]);
  }

  // 원생 등록 시 핸들러
  const handleChildrenRegisterBtnClick = async () => {
    try {
      await ClassUserApi.register({
        childrenId: selectedChildren,
        classId: classId,
      });

      // 자녀 데이터를 불러와 기존 ChildrenList 에 값을 추가한다.
      const classDetailResponse = await ClassApi.detail({ id: classId });
      const { data: classDetailData, meta: classDetailMeta } = classDetailResponse.data;

      setChildrenList(classDetailData.children);
      setTotalChildrenRowCount(classDetailData.children.length);
    } catch (err) {
      console.log(err);
      if (err instanceof AxiosError && err.response.data.message === '수업중복에러') {
        alert('이미 수업에 등록된 원생입니다.');
      } else {
        alert('원생 등록 중 오류가 발생했습니다.');
      }
    } finally {
      setIsOpenChildrenSearchDialog(false);
      resetSearchedChildren();
    }
  }

  // 원생 해제 시 핸들러
  const handleChildrenDismissBtnClick = async (childrenId: number) => {
    try {
      await ClassUserApi.remove({
        classId: classId,
        childrenId: childrenId,
      });

      setChildrenList(childrenList.filter(element => element.id !== childrenId));
    } catch (err) {
      console.log(err);
      alert('수업 해제 중 오류가 발생했습니다.');
    }
  }

  /*
    원생 검색 끝
   */

  // 검증 여부
  const [isAllValidated, setIsAllValidated] = useState(false);

  // 등록 State
  const [inputs, setInputs] = useState({
    name: '',
    day: [],
    memo: '',
    userId: '',
  });

  const [teacherList, setTeacherList] = useState([]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await TeacherApi.list();
      const { data } = response.data;
      setTeacherList(data);

      const classDetailResponse = await ClassApi.detail({ id: classId });
      const { data: classDetailData, meta: classDetailMeta } = classDetailResponse.data;

      setInputs({
        userId: String(classDetailData.user.id),
        name: classDetailData.name,
        day: classDetailData.day.split(','),
        memo: classDetailData.memo,
      });

      setChildrenList(classDetailData.children);
      setTotalChildrenRowCount(classDetailData.children.length);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  // input 핸들러
  const handleInputsChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  }

  // 선생님 셀렉트 박스 변경을 핸들링한다.
  const handleTeacherChange = (event) => {
    setInputs({
      ...inputs,
      userId: event.target.value
    });
  };

  // 체크박스 핸들러
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.target;

    if (name == 'day') {
      if (checked && !inputs.day.includes(value)) {
        setInputs({
          ...inputs,
          day: [
            ...inputs.day,
            value,
          ]
        });
      } else if (!checked) {
        setInputs({
          ...inputs,
          day: inputs.day.filter(eachServiceItem => eachServiceItem != value),
        });
      }
    } else {
      setInputs({
        ...inputs,
        [name]: value,
      });
    }
  }

  // 수정 핸들러
  const handleUpdateBtnClick = async () => {
    try {
      const response = await ClassApi.update({ id: classId }, {
        userId: Number(inputs.userId),
        name: inputs.name,
        memo: inputs.memo,
        day: inputs.day.join(','),
      });

      if (response.status === HttpStatusCode.Ok) {
        navigator('/class', { replace: true });
      }
    } catch (err) {
      alert('수정 중 오류가 발생했습니다.');
    }
  }

  // 삭제 핸들러
  const handleDeleteBtnClick = async () => {
    try {
      const response = await ClassApi.remove({ id: classId });

      if (response.status === HttpStatusCode.Ok) {
        navigator('/class', { replace: true });
      }
    } catch (err) {
      console.log(err);
      alert('삭제 중 오류가 발생했습니다.');
    }
  }

  // 원생 내역 검색 버튼 클릭 시 검색 핸들러
  const handleChildrenSearchBtnClick = async () => {
    try {
      const searchChildrenKeyword = searchChildrenRef.current.value;
      const response = await AcademyChildrenApi.list({
        academyId: parseInt(localStorage.getItem('academyId')),
        search: searchChildrenKeyword
      });

      const { data: searchedChildrenList } = response.data;
      setSearchedChildrenList(searchedChildrenList);

    } catch (err) {
      console.log(err);
      alert('검색 중 오류가 발생했습니다.');
    }
  }

  // 매 변경 시마다 검증 여부를 체크한다.
  useEffect(() => {
    if (
      _.isEmpty(inputs.userId) ||
      _.isEmpty(inputs.name) ||
      inputs.day.length === 0
    ) {
      setIsAllValidated(false);
    } else {
      setIsAllValidated(true);
    }
  });

  // 초기 데이터 불러오기
  useEffect(() => {
    fetchData();
  }, []);

  // 원생 테이블
  const childrenCols: GridColDef[] = [
    makeGridCol({ field: 'createdAt', headerName: '신청날짜', width: 400 }),
    makeGridCol({ field: 'parentUserName', headerName: '학부모', width: 150 }),
    makeGridCol({ field: 'name', headerName: '자녀', width: 150 }),
    makeGridCol({ field: 'status', headerName: '상태', width: 120 }),
    makeGridCol({
      field: '해제',
      headerName: '',
      renderCell: params => {
        const childrenId = params.row['id'];
        return <CustomButton text={ '해제' } onClick={ () => handleChildrenDismissBtnClick(childrenId) }/>
      },
    }),
  ];

  let childrenRows = [
    ...childrenList.map(eachChild => {
      return {
        ...eachChild,
        createdAt: dayjs(eachChild.createdAt).format('YYYY-MM-DD HH:mm'),
        status: eachChild.confirm ? '완료' : '신청중',
      }
    })
  ];

  if (isLoading) return <DimmerLoading/>

  return (
    <DetailScaffold
      title={ '수업 관리 상세' }
      showUpdateBtn={ true }
      showDeleteBtn={ true }
      handleUpdateBtnClick={ handleUpdateBtnClick }
      handleDeleteBtnClick={ handleDeleteBtnClick }
      isAllValidated={ isAllValidated }
    >

      <WidgetDialog
        isOpen={ isOpenChildrenSearchDialog }
        title={ '원생 내역 등록하기' }
        showConfirmButton={ true }
        confirmBtnText={ '등록하기' }
        handleConfirmBtnClick={ handleChildrenRegisterBtnClick }
        handleCloseBtnClick={ () => {
          setIsOpenChildrenSearchDialog(false);
          resetSearchedChildren();
        } }
        size={ 'sm' }
      >
        <div className={ 'mt-4 flex flex-col gap-y-2' }>
          {/* 검색 텍스트필드 */ }
          <div className={ 'flex flex-row items-center justify-between gap-x-4' }>
            <TextField
              className={ 'w-4/5' }
              name={ 'searchChildrenKeyword' }
              label={ '자녀명, 자녀 연락처, 학부모명, 학부모 연락처로 검색' }
              placeholder={ '자녀명, 자녀 연락처, 학부모명, 학부모 연락처' }
              size={ 'small' }
              inputRef={ searchChildrenRef }
            />
            <CustomButton
              text={ '검색' }
              height={ 'h-[37px]' }
              width={ 'w-1/5' }
              onClick={ handleChildrenSearchBtnClick }
            />
          </div>

          {
            !_.isEmpty(searchedChildrenList) &&
              <table className={ 'table w-full border' }>
                  <thead>
                  <tr className={ 'text-center' }>
                      <th className={ 'bg-title' }></th>
                      <th className={ 'text-base bg-title' }>자녀명</th>
                      <th className={ 'text-base bg-title' }>자녀 연락처</th>
                      <th className={ 'text-base bg-title' }>학부모명</th>
                      <th className={ 'text-base bg-title' }>학부모 연락처</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    searchedChildrenList.map(element => {
                      return (
                        <tr key={ element.childrenId } className={ 'text-center bg-table-body border' }>
                          <td>
                            <input
                              type={ 'radio' }
                              name={ 'selectedChildren' }
                              className={ 'radio radio-primary radio-sm' }
                              // checked={ element.id === selectedClass }
                              onChange={ () => setSelectedChildren(element.childrenId) }
                            />
                          </td>
                          <td>{ element.name }</td>
                          <td>{ element.childrenTel }</td>
                          <td>{ element.parentName }</td>
                          <td>{ element.tel }</td>
                        </tr>
                      );
                    })
                  }
                  </tbody>
              </table>
          }

        </div>
      </WidgetDialog>

      <GridDataContainer colCount={ 1 }>
        <GridDataItem
          required={ true }
          title={ '선생님' }
          content={
            <TextField
              id="outlined-select-sns-provider"
              select
              label="선택"
              value={ inputs.userId }
              onChange={ handleTeacherChange }
              size={ 'small' }
              sx={ {
                width: 250
              } }
            >
              { teacherList.map((option) => (
                <MenuItem key={ option.id } value={ String(option.id) }>
                  { option.userName }
                </MenuItem>
              )) }
            </TextField>
          }/>

        <GridDataItem
          required={ true }
          title={ '수업명' }
          content={
            <TextField
              name={ 'name' }
              label={ '수업명' }
              placeholder={ '수업명' }
              size={ 'small' }
              value={ inputs.name }
              onChange={ handleInputsChange }
            />

          }/>

        <GridDataItem
          required={ true }
          autoSize={ true }
          title={ '요일' }
          content={
            <div className={ 'flex flex-col gap-y-2' }>
              { ['월', '화', '수', '목', '금', '토', '일'].map((value) => (
                <div key={ value } className={ 'flex flex-row items-center gap-x-2' }>
                  <input
                    name={ 'day' }
                    type={ 'checkbox' }
                    className={ 'checkbox checkbox-primary checkbox-sm' }
                    value={ value }
                    checked={ inputs.day.includes(value) }
                    onChange={ handleCheckboxChange }
                  />
                  <label htmlFor={ value }>{ value }</label>
                </div>
              )) }
            </div>
          }/>

        <GridDataItem
          autoSize={ true }
          title={ '메모' }
          content={
            <TextField
              className={ 'w-96' }
              name={ 'memo' }
              multiline
              minRows={ 10 }
              value={ inputs.memo }
              onChange={ handleInputsChange }
            />
          }/>

      </GridDataContainer>

      <div className={ 'overflow-auto mt-8 flex flex-col gap-y-2' }>
        <div className={ 'flex flex-row items-center justify-between' }>
          <DetailTitle mainText={ '원생 내역' } subText={ '' }/>
          <Button
            variant="contained"
            color={ 'secondary' }
            onClick={ () => {
              setIsOpenChildrenSearchDialog(true);
            } }
          >
            등록하기
          </Button>
        </div>
        <CustomDataGrid
          isLoading={ isChildrenLoading }
          totalRowCount={ totalChildrenRowCount }
          rows={ childrenRows }
          columns={ childrenCols }
          onPageChange={ page => setCurrentChildrenPage(page) }
          page={ currentChildrenPage - 1 }
        />
      </div>


    </DetailScaffold>
  );
}

export default ClassDetail;
