import { axiosInstance } from "../config/interceptor/axios-interceptor";
import { BaseResponse } from "../models/base-response";
import { ClassListModel } from "../models/class/class-list-model";
import { ClassRegisterModel } from "../models/class/class-register-model";
import { ClassDetailModel } from "../models/class/class-detail-model";
import { ClassUpdateModel } from "../models/class/class-update-model";

export default {
  register(data: ClassRegisterModel) {
    return axiosInstance.post<BaseResponse<any>>('/class/register', data);
  },

  list(queries: {
    page?: number,
    limit?: number,
    // 학원 고유키
    academyId?: number,
    // 자녀 고유키
    childrenId?: number,
    // 나의 학원 고유키
    myAcademyId?: number,
    // 검색
    search?: string,
    // 요일
    day?: string,
    // 선생님 고유키
    teacherId?: number,
  }) {
    return axiosInstance.get<BaseResponse<ClassListModel[]>>('/class/list', {
      params: {
        ...queries,
        limit: queries.limit ? queries.limit : 15,
      }
    });
  },

  detail(queries: { id: number }) {
    return axiosInstance.get<BaseResponse<ClassDetailModel>>('/class/detail', { params: queries });
  },

  update(queries: { id: number }, data: ClassUpdateModel) {
    return axiosInstance.put<BaseResponse<any>>('/class/update', data, { params: queries });
  },

  remove(queries: { id: number }) {
    return axiosInstance.delete<BaseResponse<any>>('/class/remove', { params: queries });
  }
}
