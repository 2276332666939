import React, { ChangeEvent, useEffect, useState } from "react";
import { TextField } from "@mui/material";
import DetailScaffold from "../../../components/Detail/Scaffold";
import GridDataContainer from "../../../components/GridData/GridDataContainer";
import GridDataItem from "../../../components/GridData/GridDataItem";
import _ from "lodash";
import TeacherApi from "../../../api/teacher-api";
import { HttpStatusCode } from "axios";
import { useNavigate } from "react-router";
import DetailTitle from "../../../components/Detail/Text/DetailTitle";
import DetailInputFile from "../../../components/Detail/Input/File";
import DetailImageThumbnail from "../../../components/Detail/Image/Thumbnail";
import { convertLocalFileToPath } from "../../../util/common-utils";
import CustomDatePicker from "../../../components/TextField/DatePicker";
import dayjs, { Dayjs } from "dayjs";

interface InputInterface {
  loginId: string,
  password: string,
  tel: string,
  etc: string,
  name: string,
  joinDate: string,
}

interface ThumbnailInterface {
  file: File,
  preview: string,
}

const TeacherCreate = () => {
  const navigator = useNavigate();

  const [isAllValidated, setIsAllValidated] = useState(false); // 검증 여부
  const [isPasswordValidated, setIsPasswordValidated] = useState<boolean>(null); // 비밀번호 검증 여부

  // 선생님 등록 input state
  const [inputs, setInputs] = useState<InputInterface>({
    loginId: '',
    password: '',
    tel: '',
    etc: '',
    name: '',
    joinDate: '',
  });

  // 썸네일 미리보기 및 썸네일 파일
  const [thumbnail, setThumbnail] = useState<ThumbnailInterface>({
    file: null,
    preview: null,
  });

  // input 핸들러
  // name 이 password 일 경우, 비밀번호도 함께 검증한다.
  const handleInputsChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {name, value} = event.target;

    setInputs({
      ...inputs,
      [name]: value,
    });

    // 비밀번호 검증
    if (name === 'password') {
      // 비밀번호가 공백일 경우, isPasswordValidated 를 null 로 변경한다. (초기상태)
      if (_.isEmpty(value)) {
        setIsPasswordValidated(null);
        return;
      }

      let reg: RegExp = /^(?=.*[a-zA-Z])(?=.*[0-9]).{5,25}$/
      if (value.match(reg)) {
        setIsPasswordValidated(true);
      } else {
        setIsPasswordValidated(false);
      }
    }
  }

  // DatePicker 핸들러
  const handleJoinDateChange = (value: Dayjs | null) => {
    if (!value) {
      return;
    }

    setInputs({
      ...inputs,
      joinDate: value.format('YYYY-MM-DD'),
    });
  }

  // 등록 핸들러
  const handleCreateBtnClick = async () => {
    try {
      const response = await TeacherApi.register({
        ...inputs
      });
      const {data} = response.data;

      if (response.status === HttpStatusCode.Ok) {
        if (thumbnail.file) {
          const fileResponse = await TeacherApi.fileRegister({teacherId: data}, {image: thumbnail.file});

          if (fileResponse.status === HttpStatusCode.Ok) {
            navigator('/teacher', {replace: true});
          }
        } else {
          navigator('/teacher', {replace: true});
        }
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        alert(err?.response?.data?.message);
        return;
      }
      alert('등록 중 오류가 발생했습니다.');
    }
  }

  // 매 변경 시마다 검증 여부를 체크한다.
  useEffect(() => {
    if (
      _.isEmpty(inputs.loginId) ||
      _.isEmpty(inputs.name) ||
      _.isEmpty(inputs.password) ||
      !isPasswordValidated ||
      _.isEmpty(inputs.tel) ||
      _.isEmpty(inputs.name)
    ) {
      setIsAllValidated(false);
    } else {
      setIsAllValidated(true);
    }
  });

  // 썸네일 파일 핸들러
  const handleThumbnailChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file: File = event.target.files[0];
    const preview = await convertLocalFileToPath(file);

    setThumbnail({
      file: file,
      preview: preview
    });
  }

  // 썸네일 제거 핸들러
  const handleThumbnailDeleteBtnClick = () => {
    setThumbnail({
      file: null,
      preview: null,
    });
  }

  return (
    <DetailScaffold
      title={ '선생님 관리 등록' }
      showCreateBtn={ true }
      handleCreateBtnClick={ handleCreateBtnClick }
      isAllValidated={ isAllValidated }
    >
      <GridDataContainer colCount={ 1 }>

        <GridDataItem
          required={ true }
          title={ '성명' }
          content={
            <TextField
              name={ 'name' }
              label={ '성명' }
              placeholder={ '성명' }
              size={ 'small' }
              value={ inputs.name }
              onChange={ handleInputsChange }
            />

          }/>


        <GridDataItem
          required={ true }
          title={ '입사날짜' }
          content={
            <CustomDatePicker
              name={ 'joinDate' }
              value={ inputs.joinDate ? dayjs(inputs.joinDate) : null }
              onChange={ (value, ignoreKeyboardInputValue) => handleJoinDateChange(value) }
              label={ '입사날짜' }
            />
          }/>

        <GridDataItem
          required={ true }
          title={ '전화번호' }
          content={
            <TextField
              name={ 'tel' }
              label={ '전화번호' }
              placeholder={ '전화번호' }
              size={ 'small' }
              value={ inputs.tel }
              onChange={ handleInputsChange }
            />

          }/>

        <GridDataItem
          required={ true }
          title={ '아이디' }
          content={
            <TextField
              name={ 'loginId' }
              label={ '아이디' }
              placeholder={ '아이디' }
              size={ 'small' }
              value={ inputs.loginId }
              onChange={ handleInputsChange }
            />

          }/>

        <GridDataItem
          autoSize={ true }
          required={ true }
          title={ '비밀번호' }
          content={
            <div className={ 'flex flex-col gap-y-1 justify-start items-start' }>
              <TextField
                name={ 'password' }
                type={ 'password' }
                label={ '비밀번호' }
                placeholder={ '비밀번호' }
                size={ 'small' }
                value={ inputs.password }
                onChange={ handleInputsChange }

              />
              {
                isPasswordValidated &&
                  <span className={ 'text-xs text-green-500' }>올바른 비밀번호입니다.</span>
              }
              {
                isPasswordValidated === false &&
                  <span className={ 'text-xs text-red-500' }>비밀번호는 6자 이상, 영문과 숫자를 반드시 포함해야합니다.</span>
              }
            </div>
          }/>

        <GridDataItem
          autoSize={ true }
          title={ '메모' }
          content={
            <TextField
              className={ 'w-96' }
              name={ 'etc' }
              multiline
              minRows={ 10 }
              value={ inputs.etc }
              onChange={ handleInputsChange }
            />
          }/>

      </GridDataContainer>

      {/* 썸네일 사진 */ }
      <div className={ 'mt-8' }>
        <DetailTitle mainText={ '프로필 사진' } subText={ '(선택)' }/>
        <div className={ 'mt-2' }></div>
        <div className={ 'flex flex-col inline-flex gap-y-2' }>
          <DetailInputFile onFileChange={ handleThumbnailChange }/>
          {
            thumbnail.file &&
              <DetailImageThumbnail src={ thumbnail.preview } showDeleteBtn={ true }
                                    handleDeleteBtnClick={ handleThumbnailDeleteBtnClick }
                                    objectFit={ 'object-cover' }/>
          }
        </div>
      </div>


    </DetailScaffold>
  );
}

export default TeacherCreate;