import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FC } from "react";
import { TextField } from "@mui/material";
import { Dayjs } from "dayjs";
import 'dayjs/locale/ko';

interface CustomDatePickerProps {
  label?: string;
  name: string;
  value: Dayjs;
  onChange: (value: Dayjs, keyboardInputValue?: string) => void;
  size?: 'small' | 'medium';
}

const CustomDatePicker: FC<CustomDatePickerProps> = ({label, name, value, onChange, size = 'small'}) => {
  return (
    <LocalizationProvider dateAdapter={ AdapterDayjs } adapterLocale={ 'ko' }>
      <DatePicker
        label={ label }
        value={ value }
        inputFormat={ 'YYYY-MM-DD' }
        mask={ '____-__-__' }
        onChange={ onChange }
        renderInput={ (params) => <TextField
          name={ name }
          className={ 'focus:outline focus:outline-primary' }
          size={ size }
          sx={ {width: {xs: 150, md: 247}} } { ...params }
        />
        }
      />
    </LocalizationProvider>
  );
}

export default CustomDatePicker;