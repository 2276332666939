import { useContext } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';

import { alpha, Box, darken, Divider, Drawer, lighten, styled, useTheme } from '@mui/material';

import SidebarMenu from './SidebarMenu';
import Logo from 'src/components/LogoSign';
import CustomButton from "../../../components/CustomButton";
import UserApi from "../../../api/user-api";

const SidebarWrapper = styled(Box)(
    ({theme}) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 68px;
`
);

function Sidebar() {
    const {sidebarToggle, toggleSidebar} = useContext(SidebarContext);
    const closeSidebar = () => toggleSidebar();
    const theme = useTheme();

    // 로그아웃 버튼 클릭 시 콜백
    const handleLogoutClicked = async () => {
        await UserApi.logout();
    }

    return (
        <>
            <SidebarWrapper
                sx={{
                    display: {
                        xs: 'none',
                        lg: 'inline-block'
                    },
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    // background:
                    //     theme.palette.mode === 'dark'
                    //         ? alpha(lighten(theme.header.background, 0.1), 0.5)
                    //         : darken(theme.colors.alpha.black[100], 0.5),
                    background:
                        theme.palette.mode === 'dark'
                            ? alpha(lighten(theme.header.background, 0.1), 0.5)
                            : alpha(lighten(theme.header.background, 1.0), 1.0),
                    // boxShadow:
                    //     theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none'
                    boxShadow: theme.sidebar.boxShadow
                }}
            >
                <Scrollbar>
                    <Box mt={3}>
                        <div className={'w-full flex flex-row justify-between'}>
                            <Box
                                mx={2}
                                sx={{
                                    width: 52
                                }}
                            >
                                <Logo/>
                            </Box>
                            <CustomButton
                                className={'mr-4 text-gray-600 hover:bg-gray-300 px-2 transition duration-300 rounded'}
                                text={'로그아웃'}
                                onClick={handleLogoutClicked}
                            />
                        </div>
                    </Box>
                    <Divider
                        sx={{
                            mt: theme.spacing(3),
                            mx: theme.spacing(2),
                            background: theme.colors.alpha.black[10]
                        }}
                    />
                    <SidebarMenu/>
                </Scrollbar>
                <Divider
                    sx={{
                        background: theme.colors.alpha.black[10]
                    }}
                />
                <Box p={2}>
                    <span className={'text-gray-600'}>학원나우 관리자페이지</span>
                </Box>
            </SidebarWrapper>
            <Drawer
                sx={{
                    boxShadow: `${theme.sidebar.boxShadow}`
                }}
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={sidebarToggle}
                onClose={closeSidebar}
                variant="temporary"
                elevation={9}
            >
                <SidebarWrapper
                    sx={{
                        background:
                            theme.palette.mode === 'dark'
                                ? theme.colors.alpha.white[100]
                                : lighten(theme.colors.alpha.trueWhite[100], 0.5)
                    }}
                >
                    <Scrollbar>
                        <Box mt={3}>
                            <div className={'w-full flex flex-row justify-between'}>
                                <Box
                                    mx={2}
                                    sx={{
                                        width: 52
                                    }}
                                >
                                    <Logo/>
                                </Box>
                                <CustomButton
                                    className={'mr-4 text-gray-600 hover:bg-gray-300 px-2 transition duration-300 rounded'}
                                    text={'로그아웃'}
                                    onClick={handleLogoutClicked}
                                />
                            </div>
                        </Box>
                        <Divider
                            sx={{
                                mt: theme.spacing(3),
                                mx: theme.spacing(2),
                                background: theme.colors.alpha.black[10]
                            }}
                        />
                        <SidebarMenu/>
                    </Scrollbar>
                </SidebarWrapper>
            </Drawer>
        </>
    );
}

export default Sidebar;
