import CustomButton from "../../CustomButton";
import React, { FC, ReactNode } from "react";
import CustomPrimaryButton from "../../CustomButton/PrimaryButton";

interface DetailScaffoldProps {
    title: string;
    showCreateBtn?: boolean;
    showDeleteBtn?: boolean;
    showUpdateBtn?: boolean;
    showOtherBtn?: boolean;
    handleCreateBtnClick?: () => void;
    handleDeleteBtnClick?: () => void;
    handleUpdateBtnClick?: () => void;
    handleOtherBtnClick?: () => void;
    otherBtnText?: string;
    isAllValidated?: boolean;
    children: ReactNode;
}

/**
 *
 * @param title 제목
 * @param showCreateBtn 등록 버튼 렌더 여부
 * @param showDeleteBtn 삭제 버튼 렌더 여부
 * @param showUpdateBtn 수정 버튼 렌더 여부
 * @param showOtherBtn 기타 버튼 렌더 여부
 * @param handleCreateBtnClick 등록 버튼 핸들러
 * @param handleDeleteBtnClick 삭제 버튼 핸들러
 * @param handleUpdateBtnClick 수정 버튼 핸들러
 * @param handleOtherBtnClick 기타 버튼 핸들러
 * @param otherBtnText 기타 버튼 텍스트
 * @param isAllValidated 데이터 검증 여부 (undefined 일 경우 의도에 의해 검증하지 않는다고 판단하며, false 또는 true 일 경우 등록/수정 버튼의 활성화 여부를 결정한다.)
 * @param children
 * @constructor
 */
const DetailScaffold: FC<DetailScaffoldProps> = (
    {
        title,
        showCreateBtn = false,
        showDeleteBtn = false,
        showUpdateBtn = false,
        showOtherBtn = false,
        handleCreateBtnClick,
        handleDeleteBtnClick,
        handleUpdateBtnClick,
        handleOtherBtnClick,
        otherBtnText= '기타',
        isAllValidated ,
        children,
    }) => {
    // 각 버튼에 따라 해당하는 핸들러가 없을 경우 예외를 반환한다.
    if (
        (showCreateBtn && !handleCreateBtnClick) ||
        (showUpdateBtn && !handleUpdateBtnClick) ||
        (showDeleteBtn && !handleDeleteBtnClick) ||
        (showOtherBtn && !handleOtherBtnClick)
    ) {
        alert('각 버튼의 활성화 여부가 참일 경우, 활성화된 버튼에 대한 핸들러는 반드시 존재해야합니다.');
        return (
            <div className={ 'w-screen h-screen flex justify-center items-center' }>
                <span className={'text-xl font-bold mr-[250px]'}>핸들러를 정의해주세요.</span>
            </div>
        );
    }

    // 검증 활성화 여부 플래그
    const enableToUseIsAllValidateVar: boolean = isAllValidated != undefined;

    return (
        <>
            <div className={'container p-8'}>
                <div className={'container w-full h-full border rounded-xl shadow bg-white'}>
                    {/* 타이틀 */}
                    <div className={'p-8 flex justify-between items-center'}>
                        <span className={'font-bold text-lg'}>{title}</span>
                        <div className={ 'flex flex-row gap-x-2' }>
                            {
                                showCreateBtn &&
                                <CustomButton
                                    text={'등록하기'} color={'bg-primary'}
                                    disabled={enableToUseIsAllValidateVar ? !isAllValidated : false}
                                    hoverColor={'hover:bg-primary-hover'}
                                    activeColor={'active:bg-primary-active'}
                                    onClick={ handleCreateBtnClick }
                                />
                            }
                            {
                                showUpdateBtn &&
                                <CustomButton
                                    type={'submit'}
                                    text={'수정하기'} color={'bg-primary'}
                                    disabled={enableToUseIsAllValidateVar ? !isAllValidated : false}
                                    hoverColor={'hover:bg-primary-hover'}
                                    activeColor={'active:bg-primary-active'}
                                    onClick={ handleUpdateBtnClick }
                                />
                            }
                            {
                                showDeleteBtn &&
                                <CustomButton
                                    text={'삭제하기'} color={'bg-red-500'}
                                    hoverColor={'hover:bg-red-600'}
                                    activeColor={'active:bg-red-700'}
                                    onClick={ handleDeleteBtnClick }
                                />
                            }
                            {
                                showOtherBtn &&
                                <CustomPrimaryButton
                                    text={ otherBtnText }
                                    onClick={ handleOtherBtnClick }
                                />
                            }
                        </div>
                    </div>
                    <hr className={'border'}/>

                    {/* 고객 관리 데이터 */}
                    <div className={'p-8'}>
                        { children }
                    </div>
                </div>
            </div>
        </>
    );
}

export default DetailScaffold;