import GridDataContainer from "../../components/GridData/GridDataContainer";
import GridDataItem from "../../components/GridData/GridDataItem";
import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import { GridColDef } from "@mui/x-data-grid";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";
import { convertStringToYYYYMMDDWithDot } from "../../util/date-utils";
import TeacherApi from "../../api/teacher-api";
import { TeacherListModel } from "../../models/teacher/teacher-list-model";
import ListScaffold from "../../components/List/Scaffold";
import CustomDataGrid from "../../components/GridData/CustomDataGrid";
import { makeGridCol } from "../../util/grid-col-def-utils";
import { calcOrderNumber } from "../../util/common-utils";

/**
 * 선생님 관리 리스트
 * @constructor
 */
const Teacher = () => {
  ////////////////////////////////////////////////////////
  // State 및 핸들러, 관련 변수 정의
  ////////////////////////////////////////////////////////

  const navigator = useNavigate();

  const [teacherListData, setTeacherListData] = useState<TeacherListModel[]>(); // 선생님 데이터
  const [totalRowCount, setTotalRowCount] = useState(0); // 전체 행 개수
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  const [isLoading, setIsLoading] = useState(false); // 로딩 상태
  const [orderList, setOrderList] = useState<number[]>([]) // 순번 리스트

  /**
   * 필터 ref (성능 최적화)
   */
  const searchKeywordRef = useRef(null);
  const searchTelRef = useRef(null);

  // 검색 버튼 클릭 시 핸들링한다.
  const handleSearchBtnClick = () => {
    try {
      setCurrentPage(1);
      fetchData({
        page: 1,
        searchKeyword: searchKeywordRef.current.value,
        tel: searchTelRef.current.value,
      });
    } catch (err) {
      console.log(err);
      alert('검색 중 오류가 발생했습니다.');
    }
  }

  // 초기화 버튼 클릭 시 핸들러
  const handleResetBtnClick = () => {
    // 데이터를 모두 초기화
    setCurrentPage(1);
    searchKeywordRef.current.value = '';
    searchTelRef.current.value = '';
    fetchData({ page: 1, searchKeyword: '', tel: '' });
  }

  ////////////////////////////////////////////////////////
  // 데이터 전처리 및 관련 로직 정의
  ////////////////////////////////////////////////////////
  const fetchData = async ({ page, searchKeyword, tel }: { page: number, searchKeyword?: string, tel?: string, }) => {
    try {
      setIsLoading(true);
      const response = await TeacherApi.list({
        page,
        search: searchKeyword,
        academyId: parseInt(localStorage.getItem('academyId')),
        tel: tel,
      });
      const { data, meta } = response.data;
      setTeacherListData(data);
      setTotalRowCount(meta['totalCount']);

      setOrderList(calcOrderNumber(meta.totalCount, currentPage, 15));
    } catch (err) {
      console.log(err);
      alert('데이터를 불러오는 중 오류가 발생했습니다.');
    } finally {
      setIsLoading(false);
    }
  }

  // 초기 데이터 불러오기
  useEffect(() => {
    fetchData({
      page: currentPage,
      searchKeyword: searchKeywordRef.current.value,
      tel: searchTelRef.current.value,
    });
  }, [currentPage]);

  // 테이블 리스트 컬럼
  const columns: GridColDef[] = [
    makeGridCol({ field: 'order', headerName: '순번' }),
    makeGridCol({ field: 'createdAt', headerName: '등록 날짜' }),
    makeGridCol({ field: 'userName', headerName: '선생님' }),
    makeGridCol({ field: 'joinDate', headerName: '입사날짜' }),
    makeGridCol({ field: 'loginId', headerName: '아이디' }),
    makeGridCol({ field: 'classCount', headerName: '수업수' }),
    makeGridCol({
      field: '상세',
      headerName: '상세',
      renderCell: params => {
        return <CustomButton text={ '상세' } onClick={ () => {
          const { id: teacherId } = params;
          navigator({
            pathname: '/teacher/detail',
            search: `?${ createSearchParams({
              teacherId: teacherId.toString(),
            }) }`
          });
        } }/>
      }
    }),
  ];

  // 테이블 행
  let rows = [];
  if (teacherListData) {
    rows = teacherListData.map((eachUser, index) => {
      return {
        ...eachUser,
        order: orderList[index],
        createdAt: convertStringToYYYYMMDDWithDot(eachUser.createdAt),
      };
    });
  }

  return (
    <>
      <ListScaffold
        title={ '선생님 관리' }
        filterBuilder={
          <GridDataContainer colCount={ 2 } rowCount={ 1 }>
            <GridDataItem
              title={ '이름 검색' }
              content={
                <TextField
                  className={ 'lg:w-[300px] w-80' }
                  name={ 'searchKeyword' }
                  label='선생님 이름으로 검색'
                  inputRef={ searchKeywordRef }
                  size={ 'small' }
                />
              }
            />
            <GridDataItem
              title={ '휴대폰번호 검색' }
              content={
                <TextField
                  className={ 'lg:w-[300px] w-80' }
                  name={ 'searchTel' }
                  label='휴대폰번호로 검색'
                  inputRef={ searchTelRef }
                  size={ 'small' }
                />
              }
            />
          </GridDataContainer>
        }
        handleSearchBtnClick={ handleSearchBtnClick }
        handleResetBtnClick={ handleResetBtnClick }
        bodyBuilder={
          <CustomDataGrid
            isLoading={ isLoading }
            totalRowCount={ totalRowCount }
            rows={ rows }
            columns={ columns }
            onPageChange={ (page, _) => setCurrentPage(page) }
            page={ currentPage - 1 }
          />
        }
        showRegisterBtn={ true }
        handleRegisterBtnClick={ () => {
          navigator('/teacher/create');
        } }
      />
    </>
  );
}

export default Teacher;
