import { Badge, styled, Tooltip, tooltipClasses, TooltipProps, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

const LogoWrapper = styled(Link)(
    ({theme}) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        width: 53px;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const TooltipWrapper = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.colors.alpha.trueWhite[100],
        color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 'bold',
        borderRadius: theme.general.borderRadiusSm,
        boxShadow:
            '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.colors.alpha.trueWhite[100]
    }
}));

function Logo() {
    const theme = useTheme();

    return (
        <TooltipWrapper
            title="대시보드로 이동"
            arrow
        >
            <LogoWrapper to="/dashboard">
                <Badge
                    sx={{
                        '.MuiBadge-badge': {
                            fontSize: theme.typography.pxToRem(11),
                            right: -20,
                            top: 4
                        }
                    }}
                    overlap="circular"
                    color="success"
                    badgeContent="1.0"
                >
                    <div className={'w-full h-full'}>
                        학원나우 관리자
                        {/*<img src={'/static/images/logo/title_logo.png'} alt='logo'*/}
                        {/*     className={'w-[62.3px] h-[64.7px]'}/>*/}
                        {/*<LogoSign>*/}
                        {/*  <LogoSignInner />*/}
                        {/*</LogoSign>*/}
                    </div>
                </Badge>
            </LogoWrapper>
        </TooltipWrapper>
    );
}

export default Logo;
