import { axiosInstance } from "../config/interceptor/axios-interceptor";
import { BaseResponse } from "../models/base-response";
import { AcademyChildrenDetailModel } from "../models/academy-children/academy-children-detail-model";
import { AcademyChildrenUpdateModel } from "../models/academy-children/academy-children-update-model";
import { AcademyChildrenListModel } from "../models/academy-children/academy-children-list-model";

export default {
  fileRegister(queries: { academyChildrenId: number }, data: { image: File }) {
    return axiosInstance.post('/academyChildren/file/register', data, {
      params: queries,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  list(queries: {
    page?: number,
    limit?: number,
    academyId: number,
    teacherId?: number,
    classId?: number,
    search?: string,
  }) {
    return axiosInstance.get<BaseResponse<AcademyChildrenListModel[]>>('/academyChildren/list', {
      params: {
        ...queries,
        limit: queries.limit ? queries.limit : 15,
      }
    });
  },

  detail(queries: { id: number }) {
    return axiosInstance.get<BaseResponse<AcademyChildrenDetailModel>>('/academyChildren/detail', { params: queries });
  },

  update(queries: { id: number }, data: AcademyChildrenUpdateModel) {
    return axiosInstance.put<BaseResponse<any>>('/academyChildren/update', data, { params: queries });
  },

  remove(queries: { id: number }) {
    return axiosInstance.delete<BaseResponse<any>>('/academyChildren/remove', { params: queries });
  },

  fileRemove(queries: { academyChildrenId: number }) {
    return axiosInstance.delete('/academyChildren/file/remove', { params: queries });
  }
}
