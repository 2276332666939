import React, { ChangeEvent, useEffect, useState } from "react";
import { TextField } from "@mui/material";
import DetailScaffold from "../../../components/Detail/Scaffold";
import GridDataContainer from "../../../components/GridData/GridDataContainer";
import GridDataItem from "../../../components/GridData/GridDataItem";
import _ from "lodash";
import TeacherApi from "../../../api/teacher-api";
import { HttpStatusCode } from "axios";
import { useNavigate } from "react-router";
import DetailTitle from "../../../components/Detail/Text/DetailTitle";
import DetailInputFile from "../../../components/Detail/Input/File";
import DetailImageThumbnail from "../../../components/Detail/Image/Thumbnail";
import { convertLocalFileToPath } from "../../../util/common-utils";
import { createSearchParams, useSearchParams } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import CustomPaginationButton from "../../../components/PaginationButton";
import DimmerLoading from "../../../components/Dimmer/Loading";
import CustomButton from "../../../components/CustomButton";
import CustomDatePicker from "../../../components/TextField/DatePicker";

interface thumbnailInterface {
  file: File,
  preview: string,
}

const TeacherDetail = () => {
  const navigator = useNavigate();

  const [queryParams, ignoreSetQueryParams] = useSearchParams();
  const teacherId = parseInt(queryParams.get('teacherId'));

  // 데이터 검증 여부
  const [isAllValidated, setIsAllValidated] = useState(false);
  const [isPasswordValidated, setIsPasswordValidated] = useState<boolean>(null); // 비밀번호 검증 여부

  const [isLoading, setIsLoading] = useState(true);
  const [isClassLoading, ignoreSetIsClassLoading] = useState(false); // 수업 리스트 페이징 로딩 여부
  const [classList, setClassList] = useState<any>([]); // 수업 리스트 데이터

  const [totalClassRowCount, setTotalClassRowCount] = useState<number>(0); // 전체 수업 수
  const [currentClassPage, setCurrentClassPage] = useState<number>(1);

  // 계약 등록 State
  const [inputs, setInputs] = useState({
    loginId: '',
    password: '',
    tel: '',
    etc: '',
    name: '',
    joinDate: '',
  });

  // 썸네일 미리보기 및 썸네일 파일 state
  const [thumbnail, setThumbnail] = useState<thumbnailInterface>({
    file: null,
    preview: null,
  });

  // DatePicker 핸들러
  const handleJoinDateChange = (value: Dayjs) => {
    if (!value) {
      return;
    }

    setInputs({
      ...inputs,
      joinDate: value.format('YYYY-MM-DD'),
    });
  }

  // input 핸들러
  const handleInputsChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {name, value} = event.target;

    setInputs({
      ...inputs,
      [name]: value,
    });

    // 비밀번호 검증
    if (name === 'password') {
      // 비밀번호가 공백일 경우, isPasswordValidated 를 null 로 변경한다. (초기상태)
      if (_.isEmpty(value)) {
        setIsPasswordValidated(null);
        return;
      }

      let reg: RegExp = /^(?=.*[a-zA-Z])(?=.*[0-9]).{5,25}$/
      if (value.match(reg)) {
        setIsPasswordValidated(true);
      } else {
        setIsPasswordValidated(false);
      }
    }
  }

  // 수정 핸들러
  const handleUpdateBtnClick = async () => {
    try {
      const response = await TeacherApi.update({id: teacherId} ,{
        name: inputs.name,
        joinDate: inputs.joinDate,
        tel: inputs.tel,
        loginId: inputs.loginId,
        password: _.isEmpty(inputs.password) ? null : inputs.password, // password 가 공백이 될 경우, 공백 데이터가 서버로 전송되는 것을 방지하기 위함
        etc: inputs.etc,
      }, { ignorePassword: true });

      if (response.status === HttpStatusCode.Ok) {
        // 선생님 썸네일이 변경되었을 경우, 썸네일을 추가 요청한다.
        if (thumbnail.file) {
          const fileResponse = await TeacherApi.fileRegister({teacherId: teacherId}, {image: thumbnail.file});

          if (fileResponse.status === HttpStatusCode.Ok) {
            navigator('/teacher', {replace: true});
          }
        } else {
          navigator('/teacher', {replace: true});
        }
      }
    } catch (err) {
      alert('등록 중 오류가 발생했습니다.');
    }
  }

  const handleDeleteBtnClick = async () => {
    if (confirm('삭제하시겠습니까?')) {
      try {
        const response = await TeacherApi.remove({id: teacherId});

        if (response.status === HttpStatusCode.Ok) {
          navigator('/teacher', {replace: true});
        }
      } catch {
        alert('삭제 중 오류가 발생했습니다.');
      }
    }
  }

  // 썸네일 파일 핸들러
  const handleThumbnailChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file: File = event.target.files[0];
    const preview = await convertLocalFileToPath(file);

    setThumbnail({
      file: file,
      preview: preview
    });
  }

  // 썸네일 제거 핸들러
  const handleThumbnailDeleteBtnClick = () => {
    setThumbnail({
      file: null,
      preview: null,
    });
  }

  // 데이터 로드 메소드
  const fetchData = async () => {
    try {
      setIsLoading(true);

      const response = await TeacherApi.detail({id: teacherId});
      const {data} = response.data;

      setInputs({
        name: data.name,
        loginId: data.loginId,
        tel: data.tel,
        etc: data.etc,
        joinDate: dayjs(data.joinDate).format('YYYY-MM-DD'),
        password: '',
      });

      setThumbnail({
        ...thumbnail,
        preview: data.thumbnail,
      });

      setClassList(data.classes);
      setTotalClassRowCount(data.classes.length);

    } catch {
      alert('데이터 로드 중 오류가 발생했습니다.');
    } finally {
      setIsLoading(false);
    }
  }

  // 초기 데이터 로딩
  useEffect(() => {
    fetchData();
  }, [currentClassPage]);

  // 매 변경 시마다 검증 여부를 체크한다.
  useEffect(() => {
    if (
      _.isEmpty(inputs.loginId) ||
      _.isEmpty(inputs.name) ||
      isPasswordValidated === false ||
      _.isEmpty(inputs.tel) ||
      _.isEmpty(inputs.name)
    ) {
      setIsAllValidated(false);
    } else {
      setIsAllValidated(true);
    }
  });

  // 원생 테이블 컬럼
  const childrenCols: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: '등록날짜',
      width: 200,
      sortable: false,
      headerClassName: 'bg-title text-base font-bold',
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'name',
      headerName: '수업',
      width: 150,
      sortable: false,
      headerClassName: 'bg-title text-base font-bold',
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'childrenCount',
      headerName: '회원수',
      width: 150,
      sortable: false,
      headerClassName: 'bg-title text-base',
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: '상세',
      headerName: '',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'bg-title text-base',
      sortable: false,
      renderCell: params => {
        return <CustomButton text={ '상세' } onClick={ () => {
          const {id: contractId} = params;
          navigator({
            pathname: '/class/detail',
            search: `?${ createSearchParams({
              classId: contractId.toString(),
            }) }`
          });
        } }/>
      }
    },
  ];

  // 원생 테이블 행
  let childrenRows = [
    ...classList.map(eachContract => {
      return {
        ...eachContract,
        createdAt: dayjs(eachContract.createdAt).format('YYYY-MM-DD HH:mm'),
        status: eachContract.confirm ? '완료' : '신청중',
      }
    })
  ];

  if (isLoading) return <DimmerLoading/>

  return (
    <DetailScaffold
      title={ '선생님 관리 상세' }
      showUpdateBtn={ true }
      showDeleteBtn={ true }
      handleUpdateBtnClick={ handleUpdateBtnClick }
      handleDeleteBtnClick={ handleDeleteBtnClick }
      isAllValidated={ isAllValidated }
    >
      <GridDataContainer colCount={ 1 }>

        <GridDataItem
          required={ true }
          title={ '성명' }
          content={
            <TextField
              name={ 'name' }
              label={ '성명' }
              placeholder={ '성명' }
              size={ 'small' }
              value={ inputs.name }
              onChange={ handleInputsChange }
            />

          }/>


        <GridDataItem
          required={ true }
          title={ '입사날짜' }
          content={
            <CustomDatePicker
              name={ 'joinDate' }
              value={ inputs.joinDate ? dayjs(inputs.joinDate) : null }
              onChange={ (value, ignoreKeyboardInputValue) => handleJoinDateChange(value) }
              label={ '입사날짜' }
            />
          }/>

        <GridDataItem
          required={ true }
          title={ '전화번호' }
          content={
            <TextField
              name={ 'tel' }
              label={ '전화번호' }
              placeholder={ '전화번호' }
              size={ 'small' }
              value={ inputs.tel }
              onChange={ handleInputsChange }
            />

          }/>

        <GridDataItem
          required={ true }
          title={ '아이디' }
          content={
            <TextField
              name={ 'loginId' }
              label={ '아이디' }
              placeholder={ '아이디' }
              size={ 'small' }
              value={ inputs.loginId }
              onChange={ handleInputsChange }
            />

          }/>

        <GridDataItem
          title={ '비밀번호' }
          content={
            <div className={ 'flex flex-col gap-y-1 justify-start items-start' }>
              <TextField
                name={ 'password' }
                type={ 'password' }
                label={ '비밀번호' }
                placeholder={ '비밀번호' }
                size={ 'small' }
                value={ inputs.password }
                onChange={ handleInputsChange }

              />
              {
                isPasswordValidated &&
                  <span className={ 'text-xs text-green-500' }>올바른 비밀번호입니다.</span>
              }
              {
                isPasswordValidated === false &&
                  <span className={ 'text-xs text-red-500' }>비밀번호는 6자 이상, 영문과 숫자를 반드시 포함해야합니다.</span>
              }
            </div>
          }/>

        <GridDataItem
          autoSize={ true }
          title={ '메모' }
          content={
            <TextField
              className={ 'w-96' }
              name={ 'etc' }
              multiline
              minRows={ 10 }
              value={ inputs.etc }
              onChange={ handleInputsChange }
            />
          }/>

      </GridDataContainer>

      {/* 썸네일 사진 */ }
      <div className={ 'mt-8' }>
        <DetailTitle mainText={ '프로필 사진' } subText={ '(선택)' }/>
        <div className={ 'mt-2' }></div>
        <div className={ 'flex flex-col inline-flex gap-y-2' }>
          <DetailInputFile onFileChange={ handleThumbnailChange }/>
          {
            (thumbnail.file || thumbnail.preview) &&
              <DetailImageThumbnail src={ thumbnail.preview } showDeleteBtn={ true }
                                    handleDeleteBtnClick={ handleThumbnailDeleteBtnClick }
                                    objectFit={ 'object-cover' }/>
          }
        </div>
      </div>


      <div className={ 'overflow-auto mt-8' }>
        <span className={ 'font-bold text-base text-title-text' }>수업 내역</span>
        <DataGrid
          className={ 'mt-4 w-1/2' }
          pagination
          paginationMode={ 'server' }
          loading={ isClassLoading }
          rowCount={ totalClassRowCount }
          columns={ childrenCols }
          rows={ childrenRows }
          // pageSize={ 15 }
          onPageChange={ (page, ignorePage) => {
            // 페이지는 0부터 시작하므로 값을 보정한다.
            let revisedPage = page + 1;
            setCurrentClassPage(revisedPage);
          } }
          autoHeight={ true }
          disableColumnMenu={ true }
          components={ {
            Pagination: CustomPaginationButton,
          } }
          disableSelectionOnClick={ true }
          page={ currentClassPage - 1 }
        />
      </div>


    </DetailScaffold>
  );
}

export default TeacherDetail;