import React, { FC, ReactNode } from "react";
import CustomButton from "../../CustomButton";
import Footer from "../../Footer";
import { Button } from "@mui/material";

interface ListScaffoldProps {
  title: string;
  showRegisterBtn: boolean;
  customBtnWidget?: ReactNode;
  filterBuilder?: ReactNode;
  centerBuilder?: ReactNode;
  bodyBuilder: ReactNode;
  handleRegisterBtnClick?: () => void;
  handleSearchBtnClick?: () => void;
  handleResetBtnClick?: () => void;
}

/**
 * 리스트 화면 스케폴드
 * @param title 리스트 제목
 * @param showRegisterBtn 등록하기 버튼 출력 여부
 * @param filterBuilder 출력할 필터 컴포넌트
 * @param bodyBuilder 출력할 바디 컴포넌트
 * @param handleRegisterBtnClick 등록하기 버튼 클릭 시 콜백 (showRegisterBtn 이 true 일 경우 필수)
 * @param handleSearchBtnClick 필터 컴포넌트 검색 버튼 클릭 시 콜백 (filterBuilder 존재 시 필수)
 * @param handleResetBtnClick 필터 컴포넌트 초기화 버튼 클릭 시 콜백 (filterBuilder 존재 시 필수)
 * @constructor
 */
const ListScaffold: FC<ListScaffoldProps> = (
  {
    title,
    showRegisterBtn ,
    customBtnWidget,
    filterBuilder,
    centerBuilder,
    bodyBuilder,
    handleRegisterBtnClick,
    handleSearchBtnClick,
    handleResetBtnClick,
  }
) => {
  if (filterBuilder && (!handleSearchBtnClick || !handleResetBtnClick)) {
    return (
      <div className={ 'h-screen flex justify-center items-center font-bold text-xl' }>
        filterBuilder 가 존재할 경우, handleSearchBtnClick, handleResetBtnClick 은 반드시 존재해야합니다.
      </div>
    );
  }

  if (showRegisterBtn && (!handleRegisterBtnClick)) {
    return (
      <div className={ 'h-screen flex justify-center items-center font-bold text-xl' }>
        showRegisterBtn 이 true 인 경우, handleRegisterBtnClick 은 반드시 존재해야합니다.
      </div>
    );
  }

  return (
    <div className={ 'container p-8' }>
      {
        filterBuilder &&
          <>
            { filterBuilder }
            {/* 검색 및 초기화 */ }
              <div className={ 'flex justify-end gap-x-4 mt-4 mb-8' }>
                  <CustomButton text={ '검색' } onClick={ handleSearchBtnClick }/>
                  <CustomButton text={ '초기화' } onClick={ handleResetBtnClick }/>
              </div>
          </>
      }

      {
        centerBuilder &&
        centerBuilder
      }

      {/*  데이터 컨테이너  */ }
      <div className={ 'w-full bg-white border shadow py-8 rounded-xl' }>
        {
          showRegisterBtn &&
            <div className={ 'px-8 pb-1' }>
                <Button
                    className={ 'float-right' }
                    variant="contained"
                    color={ 'secondary' }
                    onClick={ handleRegisterBtnClick }
                >
                    등록하기
                </Button>
            </div>
        }
        {/* 제목 */ }
        <div className={ 'flex flex-row justify-between' }>
          <span className={ 'text-2xl font-bold px-8 pb-8' }>{ title }</span>
          {
            customBtnWidget && customBtnWidget
          }
        </div>
        <div className={ 'px-8' }>
          { bodyBuilder }
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default ListScaffold;
