import { useEffect, useState } from "react";
import TeacherApi from "../api/teacher-api";

/**
 * 선생님 선택 셀렉트 박스에서 사용하기 위한 선생님 객체 리스트를 반환하는 커스텀 훅
 * @param options ignoreALlProperty: 셀렉트 박스 리스트 중, '전체' 항목 제외 여부
 */
const useTeacherDiffSelect = (options: { ignoreAllProperty: boolean } = { ignoreAllProperty: false }) => {
  const [teacherDiffSelectList, setTeacherDiffSelectList] = useState([]);

  const fetchData = async () => {
    // 선생님 리스트(셀렉트 박스 데이터) 를 불러온다.
    const teacherList = await TeacherApi.list({ academyId: parseInt(localStorage.getItem('academyId')) })
      .then(value => value.data.data);

    const teacherObjList = [];
    for (const eachTeacher of teacherList) {
      teacherObjList.push({
        value: eachTeacher.id,
        label: eachTeacher.userName,
      });
    }

    setTeacherDiffSelectList(options.ignoreAllProperty ? [
      ...teacherObjList,
    ] : [
      {
        value: '전체',
        label: '전체'
      },
      ...teacherObjList,
    ]);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return [teacherDiffSelectList];
}

export default useTeacherDiffSelect;