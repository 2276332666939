import { useCallback, useEffect, useState } from "react";
import ClassApi from "../api/class-api";

/**
 * 수업 선택 셀렉트 박스에서 사용하기 위한 수업 객체 리스트를 반환하는 커스텀 훅
 * @param queries
 * @param options ignoreALlProperty: 셀렉트 박스 리스트 중, '전체' 항목 제외 여부
 */
const useClassDiffSelect = (
  queries?: { teacherId: number },
  options: {
    ignoreAllProperty?: boolean,
    includeTeacherName?: boolean,
  } = {
    ignoreAllProperty: false,
    includeTeacherName: false,
  }) => {
  const [classDiffSelectList, setClassDiffSelectList] = useState([]);

  const fetchData = async () => {
    // 수업 리스트를 불러온다.
    const classList = await ClassApi.list({
      academyId: parseInt(localStorage.getItem('academyId')),
      teacherId: queries?.teacherId,
    }).then(value => value.data.data);

    const classObjList = [];
    for (const eachClass of classList) {
      classObjList.push({
        value: eachClass.id,
        label: options.includeTeacherName ? `${eachClass.name} - ${eachClass.userName}` : eachClass.name,
      });
    }

    setClassDiffSelectList(options.ignoreAllProperty ? [
      ...classObjList,
    ] : [
      {
        value: '전체',
        label: '전체'
      },
      ...classObjList,
    ])
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handler = useCallback(
    (data: any[]) => {
      setClassDiffSelectList(data);
    },
    [classDiffSelectList]
  );


  return [classDiffSelectList, handler] as const;
}

export default useClassDiffSelect;
