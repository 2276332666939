import React, { FC } from "react";

interface DetailInputFileProps {
    onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    allowMultiple?: boolean
}

const DetailInputFile: FC<DetailInputFileProps> = ({ onFileChange, allowMultiple = false }) => {
    return (
        <input
            type="file"
            className="mt-2 file-input file-input-bordered file-input-primary w-full max-w-xs hover:bg-gray-100 cursor:pointer transition duration-300"
            accept={'image/*'}
            onChange={ onFileChange }
            multiple={ allowMultiple }
        />
    );
}

export default DetailInputFile;