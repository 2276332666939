import { Backdrop, CircularProgress } from "@mui/material";
import React, { FC } from "react";

interface DimmerLoadingProps {
    loadingText?: string;
}

const DimmerLoading: FC<DimmerLoadingProps> = ({ loadingText }) => {
    return (
        <Backdrop
            sx={{color: '#ff83ad', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={true}
        >
            <div className={'flex flex-col items-center'}>
                <CircularProgress color="inherit"/>
                <span className={'mt-4 font-bold text-xl text-pink-500'}>{ !loadingText ? '초기 데이터를 로딩 중입니다...' : loadingText }</span>
            </div>
        </Backdrop>
    );
}

export default DimmerLoading;