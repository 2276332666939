import CustomPaginationButton from "../../PaginationButton";
import { DataGrid, GridCallbackDetails } from "@mui/x-data-grid";
import React, { FC } from "react";
import { GridRowsProp, GridValidRowModel } from "@mui/x-data-grid/models/gridRows";
import { CommonProps } from "@mui/material/OverridableComponent";
import { GridColumns } from "@mui/x-data-grid/models/colDef/gridColDef";

interface CustomDataGridProps<R extends GridValidRowModel = any> extends CommonProps {
  isLoading?: boolean;
  totalRowCount: number;
  rows: GridRowsProp<R>;
  columns: GridColumns<R>;
  onPageChange: (page: number, _: GridCallbackDetails) => void;
  page: number;
}

const CustomDataGrid: FC<CustomDataGridProps> = (
  {
    className,
    isLoading,
    totalRowCount,
    rows,
    columns,
    onPageChange,
    page,
  }
) => {
  return (
    <DataGrid
      className={ '!focus:outline-none ' + className }
      pagination
      paginationMode={ 'server' }
      loading={ isLoading }
      rowCount={ totalRowCount }
      rows={ rows }
      columns={ columns }
      pageSize={ 15 }
      onPageChange={ (page, details) => onPageChange(page + 1, details) }
      page={ page }
      autoHeight={ true }
      disableColumnMenu={ true }
      components={ {
        Pagination: CustomPaginationButton,
      } }
      disableSelectionOnClick={ true }
    />
  );
}

export default CustomDataGrid;
