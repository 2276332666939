import { FC, ReactNode } from 'react';

const gridColsXll = {
    1: 'xll:grid-cols-1',
    2: 'xll:grid-cols-2',
    3: 'xll:grid-cols-3',
    4: 'xll:grid-cols-4',
    5: 'xll:grid-cols-5',
    6: 'xll:grid-cols-6',
    7: 'xll:grid-cols-7',
    8: 'xll:grid-cols-8',
};

const gridRowsXll = {
    1: 'xll:grid-rows-1',
    2: 'xll:grid-rows-2',
    3: 'xll:grid-rows-3',
    4: 'xll:grid-rows-4',
    5: 'xll:grid-rows-5',
    6: 'xll:grid-rows-6',
};

interface FilterContainerProps {
    children: ReactNode,
    colCount?: number,
    rowCount?: number,
    customTemplateRows?: string,
}

/**
 * 필터에서 사용하는 그리드 형태의 컨테이너
 *
 * 화면이 특정 기준치(xll)보다 작아질 경우, Flex Row 로 변환한다.
 * @param children 내부 아이템 (GridDataItem)
 * @param colCount 열 개수
 * @param rowCount 행 개수
 * @param customTemplateRows 커스텀 Grid Row Template
 * @constructor
 */
const GridDataContainer: FC<FilterContainerProps> = ({children, colCount, rowCount, customTemplateRows}) => {
    let containerGridCols = gridColsXll[colCount];
    let containerGridRows = gridRowsXll[rowCount];

    return (
        <div className={
            `container rounded-xl w-full h-auto flex flex-col border shadow bg-white divide-x divide-y xll:grid ${containerGridCols} ${ customTemplateRows ? customTemplateRows : containerGridRows}`
        }>
            {children}
        </div>
    )
}

export default GridDataContainer;
