/**
 * 파라미터로 전달받은 이미지 경로에 대한 상세보기 팝업을 렌더링한다.
 * @param imgSrc 상세보기 팝업에 출력될 이미지 경로
 */
export const showImagePopupOnClicked = (imgSrc: string) => {
    // alert(imgSrc);
    let imageWin: any = new Image();
    imageWin.src = imgSrc;
    imageWin = window.open("", "profile_popup", "width=" + imageWin.width + "px, height=" + imageWin.height + "px");
    imageWin.document.write("<html><body style='margin:0'>");
    imageWin.document.write("<a href=javascript:window.close()><img src='" + imgSrc + "' border=0></a>");
    imageWin.document.write("</body><html>");
    imageWin.document.title = 'image';
}

/**
 * 파라미터로 전달받은 로컬 파일에 대한 임시 경로를 변경한다. (이미지 미리보기 등에 사용)
 * @param imgFile 이미지 파일
 */
export const convertLocalFileToPath = (imgFile: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(imgFile);

        reader.onloadend = () => {
            resolve(reader.result as string);
        }

        reader.onerror = reject;
    });
}

/**
 * 숫자를 전달받아 세 자리마다 콤마(,)를 붙여 반환한다.
 * @param num
 */
export const addCurrencyComma = (num) => {
    let regexp = /\B(?=(\d{3})+(?!\d))/g;
    return num.toString().replace(regexp, ',');
}

/**
 * 숫자를 전달받아 X,XXX 형태의 String 으로 포매팅한다.
 * @param num
 */
export const convertNumericIntoFormattedCurrency = (num: number): string => {
    const numStr = num.toString();

    // 만원 단위 환산
    if (numStr.length >= 5) {
        return addCurrencyComma(numStr.substring(0, numStr.length - 4));
    } else {
        return numStr;
    }
}

/**
 * 숫자를 전달받아 만, 천만 등의 한글 형태 포매팅으로 변환한다.
 * @param number
 */
export const convertNumericIntoKorean = (num: any): string => {
    num = parseInt((num + '').replace(/[^0-9]/g, ''), 10) + '';
    if (num == '0') return '0';
    const number = ['', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const unit = ['', '만', '억', '조'];
    const smallUnit = ['천', '백', '십', ''];
    const result = [];
    const unitCnt = Math.ceil(num.length / 4);
    num = num.padStart(unitCnt * 4, '0');
    const regexp = /[\w\W]{4}/g;
    const array = num.match(regexp);
    for (let i = array.length - 1, unitCnt = 0; i >= 0; i--, unitCnt++) {
        const hanValue = _makeHan(array[i]);
        if (hanValue == '') continue;
        result.unshift(hanValue + unit[unitCnt]);
    }
    function _makeHan(text: any) {
        let str = '';
        for (let i = 0; i < text.length; i++) {
            const num = text[i];
            if (num == '0')
                //0은 읽지 않는다
                continue;
            str += number[num] + smallUnit[i];
        }
        return str;
    }

    return result.join('');
};

/**
 * [totalPage], [currentPage] 와 [limit] 를 전달받아 순번 리스트를 내림차순으로 반환한다.
 * @param totalCount 전체 데이터 개수
 * @param currentPage 현재 페이지
 * @param limit 페이지 당 출력 데이터 최대 개수
 */
export const calcOrderNumber = (totalCount: number, currentPage: number, limit: number): number[] => {
    let orderList = [];

    if (totalCount <= limit) {
        // totalCount 가 limit 보다 작은 경우, totalCount 로부터 내림차순 순번 리스트를 계산하여 return 한다.
        for (let i=totalCount; i > 0; i--) {
            orderList.push(i);
        }

        return orderList;
    } else {
        // totalCount 가 limit 보다 큰 경우(페이지가 2 이상이 경우), 순번을 계산하여 return 한다.
        if (currentPage === 1) {
            const endOrderNumber = totalCount - limit; // 종료 순번
            for (let i=totalCount; i>endOrderNumber; i--) {
                orderList.push(i);
            }

            return orderList;
        } else {
            const revisedCurrentPage = currentPage - 1;
            const startOrderNumber = totalCount - (limit * revisedCurrentPage); // 시작 순번
            const endOrderNumber = startOrderNumber - limit; // 종료 순번

            for (let i=startOrderNumber; i>endOrderNumber; i--) {
                orderList.push(i);
            }

            return orderList;
        }
    }
}
