import { axiosInstance } from "../config/interceptor/axios-interceptor";
import { BaseResponse } from "../models/base-response";
import { ChildrenListModel } from "../models/children/children-list-model";
import { ChildrenRegisterModel } from "../models/children/children-register-model";

export default {
  register(data: ChildrenRegisterModel) {
    return axiosInstance.post<BaseResponse<{id: number}>>('/children/register', data);
  },

  fileRegister(queries: { childrenId: number }, data: { image: File }) {
    return axiosInstance.post<BaseResponse<any>>('/children/file/register', data, {
      params: queries,
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
  },

  list(queries: {
    page?: number,
    // 학원 고유키
    academyId?: number,
    startDate?: string,
    endDate?: string,
    // 선생님 고유키
    teacherId?: number,
    // 수업 고유키
    classId?: number,
    search?: string,
  }) {
    return axiosInstance.get<BaseResponse<ChildrenListModel[]>>('/children/adminList', { params: queries });
  },

  detail(queries: { id: number }) {
    return axiosInstance.get<BaseResponse<any>>('/children/detail', { params: queries });
  },

  remove(queries: { id: number }) {
    return axiosInstance.delete('/children/remove', { params: queries });
  }
}
