import { MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { ChangeEvent, FC } from "react";

interface SelectBoxProps {
  className?: string;
  label?: string;
  name?: string;
  value: any;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  size?: 'small' | 'medium';
  width?: number;
  itemList: Array<any>;
}

const SelectBox: FC<SelectBoxProps> = (
  {
    className,
    label,
    name,
    value,
    onChange,
    size = 'small',
    width = 200,
    itemList,
  }
) => {
  return (
    <TextField
      className={ className }
      select
      name={ name }
      label={ label }
      value={ value }
      onChange={ onChange }
      size={ size }
      sx={ {
        width: width
      } }
    >
      { itemList.map((option) => (
        <MenuItem key={ option.value } value={ option.value }>
          { option.label }
        </MenuItem>
      )) }
    </TextField>
  );
}

export default SelectBox;
