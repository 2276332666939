import { FC, ReactNode } from 'react';

const girdColSpan = {
    1: 'col-span-1',
    2: 'col-span-2',
    3: 'col-span-3',
    4: 'col-span-4',
    5: 'col-span-5',
    6: 'col-span-6',
    7: 'col-span-7',
    8: 'col-span-8',
}

const gridRowSpan = {
    1: 'row-span-1',
    2: 'row-span-2',
    3: 'row-span-3',
}

interface GridDataItemProps {
    title: string;
    required?: boolean;
    content: ReactNode;
    colSpan?: number;
    rowSpan?: number;
    autoSize?: boolean;
}

/**
 * FilterContainer 내에서 쓰이는 아이템
 * @param title 필터 제목
 * @param required 필수 여부
 * @param content 필터 컴포넌트
 * @param colSpan 해당 필터가 얼마나 많은 Column 을 차지할 것인지에 대한 정수값
 * @param rowSpan 해당 필터가 얼마나 많은 Row 을 차지할 것인지에 대한 정수값
 * @param autoSize 내부 타이틀 및 컨텐츠 크기 자동 조절 여부
 * @constructor
 */
const GridDataItem: FC<GridDataItemProps> = ({title, required= false, content, colSpan, rowSpan, autoSize}) => {
    let dataItemGridColSpan = girdColSpan[colSpan];
    let dataItemGridRowSpan = gridRowSpan[rowSpan];
    return (
        <div className={`container ${ !autoSize ? 'h-16' : 'h-auto' } flex flex-row divide-x ${dataItemGridColSpan} ${dataItemGridRowSpan}`}>
            {/*  필터 제목  */}
            <div className={'w-[150px] bg-title flex items-center justify-center'}>
                {title}
                {
                    required &&
                    <span className={'text-red-500'}> *</span>
                }
            </div>
            {/*  필터 컨텐츠  */}
            <div className={`flex ${ !autoSize ? '' : 'w-3/4 py-4' } items-center px-4`}>
                {content}
            </div>
        </div>
    );
}

export default GridDataItem;
