import { axiosInstance } from "../config/interceptor/axios-interceptor";
import { BaseResponse } from "../models/base-response";
import { UserModel } from "../models/user/user-model";
import { UserInfoModel } from "../models/user/user-info-model";

export default {
    // 전문가 등록
    register(data: {
        loginId: string,
        password: string,
        name: string,
        intro?: string,
        career?: string,
        url?: string,
    }) {
        return axiosInstance.post<BaseResponse<UserModel>>('/user/register', data);
    },

    // 전문가 프로필 사진 등록
    fileRegister(queries: { userId: number, }, data: { image: File }) {
        return axiosInstance.post('/user/file/register', data, {
            params: queries,
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },

    // 전문가 프로필 사진 제거
    fileRemove(queries: { userId: number }) {
        return axiosInstance.delete<BaseResponse<any>>('/user/file/remove', {params: queries});
    },

    logout() {
        localStorage.removeItem('accessToken');
        return axiosInstance.get('/user/logout');
    },

    // 유저 정보 내 테이블에서 사용하는 API
    list(
        page: number,
        startDate?: string,
        endDate?: string,
        keyword?: string,
        role?: string,
        tel?: string,
        snsProvider?: string,
        isContract?: boolean,
        ) {
        return axiosInstance.get<BaseResponse<UserModel[]>>('/user/list', {
            params: {
                page: page,
                limit: 15,
                startDate: startDate,
                endDate: endDate,
                search: keyword,
                role: role,
                tel: tel,
                provider: snsProvider,
                isContract: isContract,
            }
        })
    },

    // 유저 상세 내 친구 추천 검색을 위해 사용하는 API (주요 기능에 따른 분리)
    listForRecommendCount(queries: { recommendUserId: number }) {
        return axiosInstance.get('/user/list', {params: queries});
    },

    // 내 정보
    info() {
        return axiosInstance.get<BaseResponse<UserInfoModel>>('/user/info');
    },

    // 유저 상세
    detail(queries: {
        id: number,
    }) {
        return axiosInstance.get<BaseResponse<UserModel>>('/user/detail', {
            params: queries,
        });
    },

    // 유저 업데이트 (추후 추가 프로퍼티 필요)
    update(data: {
        name?: string,
        password?: string,
        tel?: string,
        isAdvertisementPush?: boolean,
        active?: boolean
    }, queries?: {
        userId: number,
    }) {
        return axiosInstance.put('/user/admin/update', data, {
            params: queries,
        });
    },

    // 유저 제거
    delete(queries: {
        userId: number,
    }) {
        return axiosInstance.delete('/user/delete', {
            params: queries,
        });
    }
}
