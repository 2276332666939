import { FC } from "react";
import { showImagePopupOnClicked } from "../../../../util/common-utils";

interface DetailImageThumbnailProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    width?: string;
    height?: string;
    src: string;
    showDeleteBtn?: boolean;
    handleDeleteBtnClick?: () => void;
    objectFit?: 'object-cover' | 'object-contain'
}

/**
 * 상세 등에서 사용하는 이미지 썸네일을 출력한다.
 * @param src 이미지 경로
 * @param width Tailwind 형태의 width
 * @param height Tailwind 형태의 height
 * @param showDeleteBtn 삭제 버튼 출력 여부
 * @param handleDeleteBtnClick 삭제버튼 클릭 시 핸들러
 * @param objectFit 오브젝트 핏
 * @param rest 나머지 속성
 * @constructor
 */
const DetailImageThumbnail: FC<DetailImageThumbnailProps> = ({ src , width, height, showDeleteBtn = false, handleDeleteBtnClick = () => {}, objectFit, ...rest}) => {
    return (
        <div className={`relative ${ width === undefined ? 'w-[132px]' : width }`}>
            <img
                className={`${ width === undefined ? 'w-[132px]' : width } ${ height === undefined ? 'h-[121px]' : height } rounded shadow inline-block ${objectFit === undefined ? '' : objectFit}`}
                src={ src }
                alt={'가수 썸네일'}
                onClick={() => showImagePopupOnClicked(src)}
                { ...rest }
            />
            {
                showDeleteBtn &&
                <button
                    className={'absolute p-1 right-1 top-1 text-white font-bold rounded-full w-6 h-6 text-xs bg-red-500 hover:bg-red-600 active:bg-red-700 transition duration-300 hover:animate-bounce'}
                    onClick={ handleDeleteBtnClick }
                >
                    X
                </button>
            }
        </div>
    );
}

export default DetailImageThumbnail;
