import axios, { AxiosError, AxiosHeaders, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { BASE_HOST } from "../../constant/api-constant";

export const axiosInstance = axios.create({
    baseURL: BASE_HOST,
});

// Axios 요청 인터셉터
axiosInstance.interceptors.request.use((request: InternalAxiosRequestConfig) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        (request.headers as AxiosHeaders).set({
            'Authorization': `Bearer ${accessToken}`,
        });
    }
    return request;
}, (error: Error) => {
    return Promise.reject(error);
});

// Axios 응답 인터셉터
axiosInstance.interceptors.response.use(function (response: AxiosResponse) {
    return response;
}, (error: AxiosError) => {
    console.log(error);
    const errorResponse = error.response;

    // 로그인 관련(토큰) 오류가 발생할 경우, 로그인 페이지로 이동시킨다.
    if (errorResponse.data['statusCode'] == 400 && errorResponse.data['message'] == '로그인 후 다시 시도해주세요.') {
        localStorage.removeItem('accessToken');
        window.location.href = '/auth/login';
    }
    return Promise.reject(error);
});
