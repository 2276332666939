import { GridRenderCellParams } from "@mui/x-data-grid/models/params/gridCellParams";
import * as React from "react";
import { GridValidRowModel } from "@mui/x-data-grid/models/gridRows";
import { GridColDef } from "@mui/x-data-grid";

export const makeGridCol = <R extends GridValidRowModel = any, V = any, F = V>(gridCol: {
    field: string,
    headerName: string,
    width?: number,
    renderCell?: (params: GridRenderCellParams<V, R, F>) => React.ReactNode
}): GridColDef => {
    return {
        field: gridCol.field,
        headerName: gridCol.headerName,
        width: !gridCol.width ? 150 : gridCol.width,
        align: 'center',
        headerAlign: 'center',
        headerClassName: 'bg-title text-base',
        sortable: false,
        renderCell: gridCol.renderCell,
    };
}
