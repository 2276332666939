import React, { FC, ReactNode } from "react";

interface DetailTitleProps {
    required?: boolean;
    mainText: string;
    subText: string;
    children?: ReactNode;
}

/**
 * 상세 페이지 및 등록 페이지 등에서 사용되는 각 요소에 대한 타이틀
 * @param required
 * @param mainText 메인 텍스트
 * @param subText 메인 텍스트의 우측에 배치되는 서브 텍스트
 * @param children 추가적으로 subText 의 우측에 배치되는 컴포넌트
 * @constructor
 */
const DetailTitle: FC<DetailTitleProps> = ({ required = false, mainText, subText , children}) => {
    return (
        <div className={'font-bold text-base text-title-text'}>
            <span>{ mainText }</span>
            { required &&
                <span className={'text-red-500 text-sm'}> *</span>
            }
            <span className={'text-blue-500 text-sm'}> { subText }</span>
            { children }
        </div>
    );
}

export default DetailTitle;
