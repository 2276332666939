import { useLocation } from "react-router";

export const AuthLayout = ({ children }: any) => {
    const location = useLocation();
    const pathname = location.pathname;

    // 토큰 없이 접근 가능한 페이지 정규식 및 조건 검사를 수행한다.
    // 만일, 조건식에 해당할 경우, 페이지 이동을 허가한다.
    // 그러나, 토큰이 존재할 경우에는 페이지 이동을 불허한다.
    if (typeof window !== 'undefined') {
        const authPath: RegExp = /\/auth/g
        const authorization: string | null = localStorage.getItem('accessToken');

        // 로그인 페이지에 해당하는 경우
        if (pathname.match(authPath)) {
            // 토큰이 존재할 경우, 메인 페이지로 이동시킨다.
            if (authorization) {
                window.location.href = '/dashboard';
            }
        }
    }

    return (
        <div>
            <main>{ children }</main>
        </div>
    )
}