import { axiosInstance } from "../config/interceptor/axios-interceptor";
import { BaseResponse } from "../models/base-response";
import { DuesListModel } from "../models/dues/dues-list-model";
import { DuesRegisterModel } from "../models/dues/dues-register-model";
import { DuesMetaModel } from "../models/dues/meta/dues-meta-model";

export default {
  register(data: DuesRegisterModel) {
    return axiosInstance.post<BaseResponse<DuesListModel[]>>('/dues/register', data);
  },

  list(queries: {
    page: number;
    limit?: number;
    userId?: number;
    academyId: number;
    childrenId?: number;
    classId?: number;
    status?: string;
    diff?: string;
    startDate?: string;
    endDate?: string;
    search?: string;
  }) {
    return axiosInstance.get<BaseResponse<DuesListModel[], DuesMetaModel>>('/dues/list', {
      params: {
        ...queries,
        limit: queries.limit ? queries.limit : 15,
      }
    });
  },

  remove(queries: { id: number }) {
    return axiosInstance.delete('/dues/remove', { params: queries });
  }
}
