import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { TextField } from "@mui/material";
import DetailScaffold from "../../../components/Detail/Scaffold";
import GridDataContainer from "../../../components/GridData/GridDataContainer";
import GridDataItem from "../../../components/GridData/GridDataItem";
import _ from "lodash";
import { AxiosError, HttpStatusCode } from "axios";
import { useNavigate } from "react-router";
import DetailTitle from "../../../components/Detail/Text/DetailTitle";
import DetailInputFile from "../../../components/Detail/Input/File";
import DetailImageThumbnail from "../../../components/Detail/Image/Thumbnail";
import { addCurrencyComma, convertLocalFileToPath } from "../../../util/common-utils";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { GridColDef } from "@mui/x-data-grid";
import DimmerLoading from "../../../components/Dimmer/Loading";
import CustomButton from "../../../components/CustomButton";
import { AcademyChildrenDetailModel } from "../../../models/academy-children/academy-children-detail-model";
import { ClassListModel } from "../../../models/class/class-list-model";
import AcademyChildrenApi from "../../../api/academy-children-api";
import ClassApi from "../../../api/class-api";
import CustomDataGrid from "../../../components/GridData/CustomDataGrid";
import { makeGridCol } from "../../../util/grid-col-def-utils";
import WidgetDialog from "../../../components/Dialog/WidgetDialog";
import SelectBox from "../../../components/SelectBox";
import ClassUserApi from "../../../api/class-user-api";
import DuesApi from "../../../api/dues-api";
import { DuesListModel } from "../../../models/dues/dues-list-model";
import useTeacherDiffSelect from "../../../hooks/use-teacher-diff-select";
import ConfirmDialog from "../../../components/Dialog/ConfirmDialog";

interface thumbnailInterface {
  file: File,
  preview: string,
}

const ChildrenDetail = () => {
  const navigator = useNavigate();

  const [queryParams, ignoreSetQueryParams] = useSearchParams();
  const academyChildrenId = parseInt(queryParams.get('childrenId'));
  const [childrenId, setChildrenId] = useState<number>(null);

  // 데이터 검증 여부
  const [isAllValidated, setIsAllValidated] = useState(false);

  // 다이얼로그 오픈 여부
  const [openDialog, setOpenDialog] = useState({
    classRegister: false,
    dueRegister: false,
    classDelete: false,
    duesDelete: false,
  })

  const [isLoading, setIsLoading] = useState(true);
  const [childDetailData, setChildDetailData] = useState<AcademyChildrenDetailModel>(null); // 원생 상세 데이터
  const [classList, setClassList] = useState<ClassListModel[]>([]); // 수업 리스트 데이터
  const [duesList, setDuesList] = useState<DuesListModel[]>([]); // 원비 내역 리스트 데이터

  const [totalClassRowCount, setTotalClassRowCount] = useState<number>(0); // 전체 수업 수
  const [totalDuesRowCount, setTotalDuesRowCount] = useState<number>(0); // 전체 원비 수
  const [currentClassPage, setCurrentClassPage] = useState<number>(1);
  const [currentDuesPage, setCurrentDuesPage] = useState<number>(1);

  const [teacherDiff, setTeacherDiff] = useState<string | number>('');
  const [teacherDiffSelectList] = useTeacherDiffSelect({ ignoreAllProperty: true });

  const [searchedClassList, setSearchedClassList] = useState<ClassListModel[]>([]);
  const [selectedClass, setSelectedClass] = useState<number>(null);

  const classIdForDeleteRef = useRef<number>(null);

  // 원생 input State
  const [inputs, setInputs] = useState({
    name: '',
    tel: '',
    parentName: '',
    memo: '',
    childrenTel: '',
  });

  // 썸네일 미리보기 및 썸네일 파일 state
  const [thumbnail, setThumbnail] = useState<thumbnailInterface>({
    file: null,
    preview: null,
  });

  // input 핸들러
  const handleInputsChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    setInputs({
      ...inputs,
      [name]: value,
    });
  }

  // 수정 핸들러
  const handleUpdateBtnClick = async () => {
    try {
      const response = await AcademyChildrenApi.update({ id: academyChildrenId }, {
        ...inputs,
        childrenTel: _.isEmpty(inputs.childrenTel) ? null : inputs.childrenTel
      });

      if (response.status === HttpStatusCode.Ok) {
        // 썸네일이 제거되었을 경우, 삭제 API 를 호출한다.
        if (!thumbnail.preview && !thumbnail.file) {
          await AcademyChildrenApi.fileRemove({ academyChildrenId: academyChildrenId });
        }

        // 선생님 썸네일이 변경되었을 경우, 썸네일을 추가 요청한다.
        if (thumbnail.file) {
          const fileResponse = await AcademyChildrenApi.fileRegister({ academyChildrenId: academyChildrenId }, { image: thumbnail.file });

          if (fileResponse.status === HttpStatusCode.Ok) {
            navigator('/children', { replace: true });
          }
        } else {
          navigator('/children', { replace: true });
        }
      }
    } catch (err) {
      alert('등록 중 오류가 발생했습니다.');
    }
  }

  const handleDeleteBtnClick = async () => {
    if (confirm('삭제하시겠습니까?')) {
      try {
        const response = await AcademyChildrenApi.remove({ id: academyChildrenId });

        if (response.status === HttpStatusCode.Ok) {
          navigator('/children', { replace: true });
        }
      } catch (err) {
        console.log(err);
        alert('삭제 중 오류가 발생했습니다.');
      }
    }
  }

  // 썸네일 파일 핸들러
  const handleThumbnailChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file: File = event.target.files[0];
    const preview = await convertLocalFileToPath(file);

    setThumbnail({
      file: file,
      preview: preview
    });
  }

  // 썸네일 제거 핸들러
  const handleThumbnailDeleteBtnClick = () => {
    setThumbnail({
      file: null,
      preview: null,
    });
  }

  // 데이터 로드 메소드
  const fetchData = async () => {
    try {
      setIsLoading(true);

      const response = await AcademyChildrenApi.detail({ id: academyChildrenId });
      const { data } = response.data;

      setChildrenId(data.childrenId);
      setChildDetailData(data);

      setInputs({
        name: data.name,
        parentName: data.parentName,
        childrenTel: data.childrenTel,
        tel: data.tel,
        memo: data.memo,
      });

      setThumbnail({
        ...thumbnail,
        preview: data.thumbnail,
      });

      // 원생 수업 내역 데이터 로드
      const classResponse = await ClassApi.list({
        page: currentClassPage,
        childrenId: data.childrenId
      });
      const { data: classData, meta: classMeta } = classResponse.data;

      setClassList(classData);
      setTotalClassRowCount(classMeta.totalCount);

      // 원비 내역 데이터 로드
      const duesResponse = await DuesApi.list({
        page: currentDuesPage,
        academyId: parseInt(localStorage.getItem('academyId')),
        childrenId: data.childrenId,
      });
      const { data: duesData, meta: duesMeta } = duesResponse.data;

      setDuesList(duesData);
      setTotalDuesRowCount(duesMeta.totalCount);

    } catch {
      alert('데이터 로드 중 오류가 발생했습니다.');
    } finally {
      setIsLoading(false);
    }
  }

  // 선생님 등록 시, 선생에 해당하는 수업 리스트를 불러오기 위한 핸들러
  const handleTeacherDiffChange = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      setTeacherDiff(event.target.value);
      // 수업 리스트를 불러온다.
      const classList = await ClassApi.list({
        academyId: parseInt(localStorage.getItem('academyId')),
        teacherId: parseInt(event.target.value),
      }).then(value => value.data.data);

      if (_.isEmpty(classList)) {
        alert('등록된 수업이 없습니다.');
        return;
      }

      setSearchedClassList(classList);
    } catch (err) {
      console.log(err);
      alert('수업을 불러오는 중 오류가 발생했습니다.');
    }
  }

  // 수업 다이얼로그 내에서 쓰이는 변수 리셋
  const resetClassSearchState = () => {
    setSelectedClass(null);
    setTeacherDiff('');
    setSearchedClassList([]);
  }

  // 수업 등록 버튼 클릭 시 핸들러
  const handleClassUserRegisterBtnClick = async () => {
    try {
      if (!selectedClass) {
        alert('수업을 선택해주세요.');
        return;
      }

      const classUserRegisterResponse = await ClassUserApi.register({
        childrenId: childDetailData.childrenId,
        classId: selectedClass
      });

      setOpenDialog({
        ...openDialog,
        classRegister: false,
      });

      if (classUserRegisterResponse.status === HttpStatusCode.Ok) {
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
      if (err instanceof AxiosError && err.response.status === HttpStatusCode.BadRequest) {
        alert('중복된 수업이 있습니다.');
      } else {
        alert('수업 등록 중 오류가 발생했습니다.');
      }
    }
  }

  // 수업 삭제 버튼 클릭 시 핸들러
  const handleClassDeleteBtnClick = async () => {
    try {
      const classId = classIdForDeleteRef.current;

      setOpenDialog({
        ...openDialog,
        classDelete: false,
      })

      await ClassUserApi.remove({
        childrenId: childrenId,
        classId: classId,
      });

      setClassList(classList.filter(element => element.id !== classId));

    } catch (err) {
      console.log(err);
      alert('삭제 중 오류가 발생했습니다.');
    } finally {
      classIdForDeleteRef.current = null;
    }
  }

  // 초기 데이터 로딩
  useEffect(() => {
    fetchData();
  }, [currentClassPage, currentDuesPage]);

  // 매 변경 시마다 검증 여부를 체크한다.
  useEffect(() => {
    if (
      _.isEmpty(inputs.name) ||
      _.isEmpty(inputs.parentName) ||
      _.isEmpty(inputs.tel)
    ) {
      setIsAllValidated(false);
    } else {
      setIsAllValidated(true);
    }
  });

  // 수업 테이블 컬럼
  const classCols: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: '등록날짜',
      width: 200,
      sortable: false,
      headerClassName: 'bg-title text-base font-bold',
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'teacher',
      headerName: '선생님',
      width: 150,
      sortable: false,
      headerClassName: 'bg-title text-base font-bold',
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'className',
      headerName: '수업',
      width: 300,
      sortable: false,
      headerClassName: 'bg-title text-base',
      align: 'center',
      headerAlign: 'center'
    },
    makeGridCol({
      field: 'delete', headerName: '삭제', renderCell: params => {
        const classId = params.row['id'];
        return (
          <button
            id={ classId.toString() }
            className={ 'py-1 px-4 bg-red-400 hover:bg-red-500 active:bg-red-600 duration-150 transition text-white font-bold rounded' }
            onClick={ () => {
              classIdForDeleteRef.current = classId;
              setOpenDialog({ ...openDialog, classDelete: true });
            } }
          >
            삭제
          </button>
        );
      }
    }),
    makeGridCol({ field: 'ignore', headerName: '', width: 700 }),
  ];

  // 수업 테이블 행
  let duesRows = [
    ...duesList.map(eachDues => {
      return {
        ...eachDues,
        createdAt: dayjs(eachDues.createdAt).format('YYYY-MM-DD HH:mm'),
        name: eachDues.title,
        unpaidDues: addCurrencyComma(eachDues.unpaidDues ?? 0),
        price: addCurrencyComma(eachDues.price),
      }
    })
  ];

  // 원비 테이블 컬럼
  const duesCols: GridColDef[] = [
    makeGridCol({ field: 'createdAt', headerName: '입금날짜' }),
    makeGridCol({ field: 'status', headerName: '상태' }),
    makeGridCol({ field: 'diff', headerName: '결제 구분' }),
    makeGridCol({ field: 'name', headerName: '원비' }),
    makeGridCol({ field: 'price', headerName: '금액' }),
    makeGridCol({ field: 'unpaidDues', headerName: '미납 여부' }),
    {
      field: '삭제',
      headerName: '',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'bg-title text-base',
      sortable: false,
      renderCell: params => {
        return (
          <button
            className={ 'py-1 px-4 bg-red-400 hover:bg-red-500 active:bg-red-600 duration-150 transition text-white font-bold rounded' }
            onClick={ async () => {
              const { id: duesId } = params.row;

              await DuesApi.remove({ id: parseInt(duesId) });

              setDuesList([
                ...duesList.filter(element => element.id !== parseInt(duesId)),
              ]);
              // navigator({
              //   pathname: '/class/detail',
              //   search: `?${ createSearchParams({
              //     classId: classId.toString(),
              //   }) }`
              // });
            } }
          >
            삭제
          </button>
        );
      }
    },
    makeGridCol({ field: 'ignore', headerName: '', width: 500 }),
  ];

  // 수업 테이블 행
  let classRows = [
    ...classList.map(eachClass => {
      return {
        ...eachClass,
        createdAt: dayjs(eachClass.createdAt).format('YYYY-MM-DD HH:mm'),
        teacher: eachClass.userName,
        className: eachClass.name,
      }
    })
  ];

  if (isLoading) return <DimmerLoading/>

  return (
    <DetailScaffold
      title={ '원생 관리 상세' }
      showUpdateBtn={ true }
      showDeleteBtn={ true }
      handleUpdateBtnClick={ handleUpdateBtnClick }
      handleDeleteBtnClick={ handleDeleteBtnClick }
      isAllValidated={ isAllValidated }
    >

      <WidgetDialog
        isOpen={ openDialog.classRegister }
        title={ '수업 등록하기' }
        handleCloseBtnClick={ () => {
          resetClassSearchState();
          setOpenDialog({ ...openDialog, classRegister: false });
        } }
        size={ 'sm' }
        showConfirmButton={ true }
        confirmBtnText={ '등록' }
        handleConfirmBtnClick={ handleClassUserRegisterBtnClick }
      >
        <div className={ 'flex flex-col gap-y-2' }>
          <DetailTitle mainText={ '선생님' } subText={ '' }/>
          <SelectBox className={ '!w-full' } value={ teacherDiff } onChange={ handleTeacherDiffChange }
                     itemList={ teacherDiffSelectList }/>

          {
            !_.isEmpty(searchedClassList) &&
              <table className={ 'table w-full border' }>
                  <thead>
                  <tr className={ 'text-center' }>
                      <th className={ 'bg-title w-1/5' }></th>
                      <th className={ 'text-base bg-title w-2/5' }>수업명</th>
                      <th className={ 'text-base bg-title w-2/5' }>선생님</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    searchedClassList.map(element => {
                      return (
                        <tr key={ element.id } className={ 'text-center bg-table-body border' }>
                          <td>
                            <input
                              type={ 'radio' }
                              name={ 'selectedClass' }
                              className={ 'radio radio-primary radio-sm' }
                              // checked={ element.id === selectedClass }
                              onChange={ () => setSelectedClass(element.id) }
                            />
                          </td>
                          <td>{ element.name }</td>
                          <td>{ element.userName }</td>
                        </tr>
                      );
                    })
                  }
                  </tbody>
              </table>
          }
        </div>
      </WidgetDialog>

      {/* 수업 삭제 다이얼로그 */}
      <ConfirmDialog
        isOpen={ openDialog.classDelete }
        title={ '수업 삭제' }
        content={ '수업을 삭제하시겠습니까?' }
        confirmBtnColor={ 'error' }
        onCancelBtnClick={ () => {
          setOpenDialog({
            ...openDialog,
            classDelete: false,
          });
          classIdForDeleteRef.current = null;
        } }
        onConfirmBtnClick={ handleClassDeleteBtnClick }
      />

      <GridDataContainer colCount={ 1 }>
        <GridDataItem
          required={ true }
          title={ '자녀명' }
          content={
            <TextField
              name={ 'name' }
              label={ '자녀명' }
              placeholder={ '자녀명' }
              size={ 'small' }
              value={ inputs.name }
              onChange={ handleInputsChange }
            />

          }/>


        <GridDataItem
          required={ false }
          title={ '자녀연락처' }
          content={
            <TextField
              name={ 'childrenTel' }
              label={ '자녀연락처' }
              placeholder={ '자녀연락처' }
              size={ 'small' }
              value={ inputs.childrenTel }
              onChange={ handleInputsChange }
            />

          }/>

        <GridDataItem
          required={ true }
          title={ '학부모명' }
          content={
            <TextField
              name={ 'parentName' }
              label={ '학부모명' }
              placeholder={ '학부모명' }
              size={ 'small' }
              value={ inputs.parentName }
              onChange={ handleInputsChange }
            />

          }/>

        <GridDataItem
          required={ true }
          title={ '학부모연락처' }
          content={
            <TextField
              name={ 'tel' }
              label={ '학부모연락처' }
              placeholder={ '학부모연락처' }
              size={ 'small' }
              value={ inputs.tel }
              onChange={ handleInputsChange }
            />

          }/>
        <GridDataItem
          autoSize={ true }
          title={ '사진' }
          content={
            <div className={ 'flex flex-col' }>
              <span className={ 'text-xs text-gray-500' }>* 사진 미등록 시, 얼굴인식 기능을 사용할 수 없습니다</span>
              <div className={ 'flex flex-col inline-flex gap-y-2' }>
                <DetailInputFile onFileChange={ handleThumbnailChange }/>
                {
                  (thumbnail.file || thumbnail.preview) &&
                    <DetailImageThumbnail src={ thumbnail.preview } showDeleteBtn={ true }
                                          handleDeleteBtnClick={ handleThumbnailDeleteBtnClick }
                                          objectFit={ 'object-cover' }/>
                }
              </div>
            </div>
          }
        />
        <GridDataItem
          autoSize={ true }
          title={ '메모' }
          content={
            <TextField
              className={ 'w-96' }
              name={ 'memo' }
              multiline
              minRows={ 10 }
              value={ inputs.memo }
              onChange={ handleInputsChange }
            />
          }/>
      </GridDataContainer>

      <div className={ 'mt-8' }/>

      <div className={ 'flex flex-row justify-between items-center w-full' }>
        <DetailTitle mainText={ '수업 내역' } subText={ '' }/>
        <CustomButton text={ '등록하기' } height={ 'h-[30px]' }
                      onClick={ () => setOpenDialog({ ...openDialog, classRegister: true }) }/>
      </div>

      <div className={ 'mt-4' }/>

      <CustomDataGrid
        className={ 'w-full' }
        totalRowCount={ totalClassRowCount }
        rows={ classRows }
        columns={ classCols }
        onPageChange={ page => setCurrentClassPage(page) }
        page={ currentClassPage - 1 }
      />

      <div className={ 'mt-8' }/>

      <div className={ 'flex flex-row justify-between items-center w-full' }>
        <DetailTitle mainText={ '원비 내역' } subText={ '' }/>
      </div>

      <div className={ 'mt-4' }/>

      <CustomDataGrid
        className={ 'w-full' }
        totalRowCount={ totalDuesRowCount }
        rows={ duesRows }
        columns={ duesCols }
        onPageChange={ page => setCurrentDuesPage(page) }
        page={ currentDuesPage - 1 }
      />
    </DetailScaffold>
  );
}

export default ChildrenDetail;
