import {axiosInstance} from "../config/interceptor/axios-interceptor";
import {BaseResponse} from "../models/base-response";
import {TeacherListModel} from "../models/teacher/teacher-list-model";
import {TeacherRegisterModel} from "../models/teacher/teacher-register-model";
import {TeacherDetailModel} from "../models/teacher/teacher-detail-model";
import { TeacherUpdateModel } from "../models/teacher/teacher-update-model";

export default {
  list(queries?: {
    page?: number,
    // 학원 고유키
    academyId?: number,
    // 검색
    search?: string,
    tel?: string,
  }) {
    return axiosInstance.get<BaseResponse<TeacherListModel[]>>('/teacher/list', { params: queries });
  },

  detail(queries: {
    id: number,
  }) {
    return axiosInstance.get<BaseResponse<TeacherDetailModel>>('/teacher/detail', { params: queries });
  },

  register(data: TeacherRegisterModel) {
    return axiosInstance.post<BaseResponse<any>>('/teacher/register', data);
  },

  fileRegister(queries: { teacherId: number }, data: { image: File }) {
    return axiosInstance.post<BaseResponse<any>>('/teacher/file/register', data, {
      params: queries,
      headers: {
        "Content-Type": 'multipart/form-data',
      }
    });
  },

  update(queries: { id: number }, data: TeacherUpdateModel, options?: {
    ignorePassword: boolean,
  }) {
    let revisedData = Object.assign({}, data);
    if (options.ignorePassword) {
      delete revisedData.password;
    }

    return axiosInstance.put<BaseResponse<any>>('/teacher/update', revisedData, { params: queries });
  },

  remove(queries: { id: number }) {
    return axiosInstance.delete<BaseResponse<any>>('/teacher/remove', { params: queries });
  },
}
