import React, { ChangeEvent, useEffect, useState } from "react";
import { MenuItem, TextField } from "@mui/material";
import DetailScaffold from "../../../components/Detail/Scaffold";
import GridDataContainer from "../../../components/GridData/GridDataContainer";
import GridDataItem from "../../../components/GridData/GridDataItem";
import _ from "lodash";
import ClassApi from "../../../api/class-api";
import TeacherApi from "../../../api/teacher-api";
import { HttpStatusCode } from "axios";
import { useNavigate } from "react-router";

const ClassCreate = () => {
  const navigator = useNavigate();

    // 검증 여부
    const [isAllValidated, setIsAllValidated] = useState(false);

    // 계약 등록 State
    const [inputs, setInputs] = useState({
        name: '',
        day: [],
        memo: '',
        userId: '',
    });

    const [teacherList, setTeacherList] = useState([]);

    const fetchData = async () => {
      try {
        const response = await TeacherApi.list();
        const {data} = response.data;
        setTeacherList(data);
      } catch (err) {
        console.log(err);
      }
    }

    // input 핸들러
    const handleInputsChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setInputs({
            ...inputs,
            [name]: value,
        });
    }

    // 선생님 셀렉트 박스 변경을 핸들링한다.
    const handleTeacherChange = (event) => {
        setInputs({
            ...inputs,
            userId: event.target.value
        });
    };

  // 체크박스 핸들러
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.target;

    if (name == 'day') {
      if (checked && !inputs.day.includes(value)) {
        setInputs({
          ...inputs,
          day: [
            ...inputs.day,
            value,
          ]
        });
      } else if (!checked) {
        setInputs({
          ...inputs,
          day: inputs.day.filter(eachServiceItem => eachServiceItem != value),
        });
      }
    } else {
      setInputs({
        ...inputs,
        [name]: value,
      });
    }
  }

    // 등록 핸들러
    const handleCreateBtnClick = async () => {
        try {
            const response = await ClassApi.register({
                userId: Number(inputs.userId),
                name: inputs.name,
                memo: inputs.memo,
                day: inputs.day.join(','),
            });

            if (response.status === HttpStatusCode.Ok) {
                navigator('/class', { replace: true });
            }
        } catch (err) {
            alert('등록 중 오류가 발생했습니다.');
        }
    }

    // 매 변경 시마다 검증 여부를 체크한다.
    useEffect(() => {
          if (
            _.isEmpty(inputs.userId) ||
            _.isEmpty(inputs.name) ||
            inputs.day.length === 0
          ) {
              setIsAllValidated(false);
          } else {
              setIsAllValidated(true);
          }
    });

    // 초기 데이터 불러오기
    useEffect(() => {
      fetchData();
    }, []);


    return (
      <DetailScaffold
        title={ '수업 등록' }
        showCreateBtn={ true }
        handleCreateBtnClick={ handleCreateBtnClick }
        isAllValidated={ isAllValidated }
      >
          <GridDataContainer colCount={ 1 }>

              <GridDataItem
                required={ true }
                title={ '선생님' }
                content={
                    <TextField
                      id="outlined-select-sns-provider"
                      // disabled={ checkboxInputs.leaseAgreement === '전세' }
                      select
                      label="선택"
                      value={ inputs.userId }
                      onChange={ handleTeacherChange }
                      size={ 'small' }
                      sx={ {
                          width: 250
                      } }
                    >
                      { teacherList.map((option) => (
                        <MenuItem key={ option.id } value={ String(option.id) }>
                          { option.userName }
                        </MenuItem>
                      )) }
                    </TextField>
                }/>

              <GridDataItem
                required={ true }
                title={ '수업명' }
                content={
                    <TextField
                      name={ 'name' }
                      label={ '수업명' }
                      placeholder={ '수업명' }
                      size={ 'small' }
                      value={ inputs.name }
                      onChange={ handleInputsChange }
                    />

                }/>

            <GridDataItem
              required={ true }
              autoSize={ true }
              title={ '요일' }
              content={
                <div className={ 'flex flex-col gap-y-2' }>
                  {['월','화','수','목','금','토','일'].map((value) => (
                    <div key={ value } className={ 'flex flex-row items-center gap-x-2' }>
                      <input
                        name={ 'day' }
                        type={ 'checkbox' }
                        className={ 'checkbox checkbox-primary checkbox-sm' }
                        value={ value }
                        onChange={ handleCheckboxChange }
                      />
                      <label htmlFor={ value }>{value}</label>
                    </div>
                  ))}
                </div>
              }/>

              <GridDataItem
                autoSize={ true }
                title={ '메모' }
                content={
                    <TextField
                      className={ 'w-96' }
                      name={ 'memo' }
                      multiline
                      minRows={ 10 }
                      value={ inputs.memo }
                      onChange={ handleInputsChange }
                    />
                }/>

          </GridDataContainer>

      </DetailScaffold>
    );
}

export default ClassCreate;