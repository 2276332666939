import React, { ChangeEvent, useEffect, useState } from "react";
import { TextField } from "@mui/material";
import DetailScaffold from "../../../components/Detail/Scaffold";
import GridDataContainer from "../../../components/GridData/GridDataContainer";
import GridDataItem from "../../../components/GridData/GridDataItem";
import _ from "lodash";
import { useNavigate } from "react-router";
import DetailInputFile from "../../../components/Detail/Input/File";
import DetailImageThumbnail from "../../../components/Detail/Image/Thumbnail";
import { convertLocalFileToPath } from "../../../util/common-utils";
import ChildrenApi from "../../../api/children-api";
import { HttpStatusCode } from "axios";
import AcademyChildrenApi from "../../../api/academy-children-api";

interface thumbnailInterface {
  file: File,
  preview: string,
}

const ChildrenCreate = () => {
  const navigator = useNavigate();

  // 검증 여부
  const [isAllValidated, setIsAllValidated] = useState(false);

  // 원생 등록 State
  const [inputs, setInputs] = useState({
    name: '',
    tel: '',
    parentName: '',
    memo: '',
    childrenTel: '',
  });

  // 썸네일 미리보기 및 썸네일 파일
  const [thumbnail, setThumbnail] = useState<thumbnailInterface>({
    file: null,
    preview: null,
  });

  // input 핸들러
  const handleInputsChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  // 등록 핸들러
  const handleCreateBtnClick = async () => {
    try {
      const response = await ChildrenApi.register({
        ...inputs,
        academyId: parseInt(localStorage.getItem('academyId')),
      });
      const { id } = response.data.data;

      if (response.status === HttpStatusCode.Ok) {
        if (thumbnail.file) {
          const fileResponse = await AcademyChildrenApi.fileRegister({ academyChildrenId: id }, { image: thumbnail.file });

          if (fileResponse.status === HttpStatusCode.Ok) {
            navigator('/children', { replace: true });
          }
        } else {
          navigator('/children', { replace: true });
        }
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        alert(err?.response?.data?.message);
        return;
      }
      alert('등록 중 오류가 발생했습니다.');
    }
  }

  // 매 변경 시마다 검증 여부를 체크한다.
  useEffect(() => {
    if (
      _.isEmpty(inputs.name) ||
      _.isEmpty(inputs.tel) ||
      _.isEmpty(inputs.parentName)
    ) {
      setIsAllValidated(false);
    } else {
      setIsAllValidated(true);
    }
  });


  // 썸네일 파일 핸들러
  const handleThumbnailChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file: File = event.target.files[0];
    const preview = await convertLocalFileToPath(file);

    setThumbnail({
      file: file,
      preview: preview
    });
  }

  // 썸네일 제거 핸들러
  const handleThumbnailDeleteBtnClick = () => {
    setThumbnail({
      file: null,
      preview: null,
    });
  }

  return (
    <DetailScaffold
      title={ '원생 관리 등록' }
      showCreateBtn={ true }
      handleCreateBtnClick={ handleCreateBtnClick }
      isAllValidated={ isAllValidated }
    >
      <GridDataContainer colCount={ 1 }>

        <GridDataItem
          required={ true }
          title={ '자녀명' }
          content={
            <TextField
              name={ 'name' }
              label={ '자녀명' }
              placeholder={ '자녀명' }
              size={ 'small' }
              value={ inputs.name }
              onChange={ handleInputsChange }
            />

          }/>


        <GridDataItem
          required={ false }
          title={ '자녀연락처' }
          content={
            <TextField
              name={ 'childrenTel' }
              label={ '자녀연락처' }
              placeholder={ '자녀연락처' }
              size={ 'small' }
              value={ inputs.childrenTel }
              onChange={ handleInputsChange }
            />

          }/>

        <GridDataItem
          required={ true }
          title={ '학부모명' }
          content={
            <TextField
              name={ 'parentName' }
              label={ '학부모명' }
              placeholder={ '학부모명' }
              size={ 'small' }
              value={ inputs.parentName }
              onChange={ handleInputsChange }
            />

          }/>

        <GridDataItem
          required={ true }
          title={ '학부모연락처' }
          content={
            <TextField
              name={ 'tel' }
              label={ '학부모연락처' }
              placeholder={ '학부모연락처' }
              size={ 'small' }
              value={ inputs.tel }
              onChange={ handleInputsChange }
            />

          }/>
        <GridDataItem
          autoSize={ true }
          title={ '사진' }
          content={
            <div className={ 'flex flex-col' }>
              <span className={ 'text-xs text-gray-500' }>* 사진 미등록 시, 얼굴인식 기능을 사용할 수 없습니다</span>
              <div className={ 'flex flex-col inline-flex gap-y-2' }>
                <DetailInputFile onFileChange={ handleThumbnailChange }/>
                {
                  thumbnail.file &&
                    <DetailImageThumbnail src={ thumbnail.preview } showDeleteBtn={ true }
                                          handleDeleteBtnClick={ handleThumbnailDeleteBtnClick }
                                          objectFit={ 'object-cover' }/>
                }
              </div>
            </div>
          }
        />
        <GridDataItem
          autoSize={ true }
          title={ '메모' }
          content={
            <TextField
              className={ 'w-96' }
              name={ 'memo' }
              multiline
              minRows={ 10 }
              value={ inputs.memo }
              onChange={ handleInputsChange }
            />
          }/>

      </GridDataContainer>
    </DetailScaffold>
  );
}

export default ChildrenCreate;
