import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { FC, ReactNode } from "react";

interface WidgetDialogProps {
    isOpen: boolean;
    title: string;
    showConfirmButton?: boolean;
    showCloseButton?: boolean;
    showOtherButton?: boolean;
    handleConfirmBtnClick?: () => void;
    handleCloseBtnClick: () => void;
    handleOtherBtnClick?: () => void;
    confirmBtnText?: string;
    closeBtnText?: string;
    otherBtnText?: string;
    size?: 'xs' | 'sm' | 'semiMd' | 'md' | 'lg';
    otherBtnColor?: 'primary' | 'secondary' | 'error';
    children?: ReactNode;
}

export const WidgetDialog: FC<WidgetDialogProps> = (
    {
        isOpen,
        title,
        showConfirmButton = false,
        showCloseButton = false,
        showOtherButton = false,
        handleConfirmBtnClick,
        handleCloseBtnClick,
        handleOtherBtnClick,
        confirmBtnText = '확인',
        closeBtnText = '닫기',
        otherBtnText = '기타',
        size = 'xs',
        otherBtnColor = 'secondary',
        children,
    }
) => {
    if (
        (showConfirmButton && !handleConfirmBtnClick) ||
        (showCloseButton && !handleCloseBtnClick) ||
        (showOtherButton && !handleOtherBtnClick)
    ) {
        throw new DOMException('버튼이 활성화되어있을 경우, 각 버튼에 대한 콜백이 반드시 필요합니다.');
    }

    return (
        <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={ size }
            fullWidth={true}
        >
            <DialogTitle id="alert-dialog-title">
                <div className={'flex flex-row justify-between'}>
                    <span className={'text-lg font-bold'}>{ title }</span>
                    <button className={'text-gray-500, font-bold'} onClick={ handleCloseBtnClick }>X</button>
                </div>
            </DialogTitle>
            <DialogContent>
                { children }
            </DialogContent>
            <DialogActions>
                {
                    showCloseButton &&
                    <Button onClick={ handleCloseBtnClick } color={'secondary'}>{ closeBtnText }</Button>
                }
                {
                    showConfirmButton &&
                    <Button onClick={ handleConfirmBtnClick } color={'primary'}>{ confirmBtnText }</Button>
                }
                {
                    showOtherButton &&
                    <Button onClick={ handleOtherBtnClick } color={ otherBtnColor }>{ otherBtnText }</Button>
                }
            </DialogActions>
        </Dialog>
    );
}

export default WidgetDialog;
