import { axiosInstance } from "../config/interceptor/axios-interceptor";
import { ClassUserRegisterModel } from "../models/class-user/class-user-register-model";
import { BaseResponse } from "../models/base-response";

export default {
  register(data: ClassUserRegisterModel) {
    return axiosInstance.post<BaseResponse<any>>('/classUser/register', data);
  },

  remove(queries: {
    classId: number,
    childrenId: number,
  }) {
    return axiosInstance.delete<BaseResponse<any>>('/classUser/remove', { params: queries });
  }
}
