import React, { FC, ReactNode } from "react";

interface CustomPrimaryButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    text: string;
    children?: ReactNode;
}

/**
 * 커스텀 주 버튼
 * @param text 버튼 텍스트
 * @param rest 나머지 버튼 옵션
 * @constructor
 */
const CustomPrimaryButton: FC<CustomPrimaryButtonProps> = ({ text, ...rest }) => {
    return (
        <>
            <button
                className={`px-4 py-2 font-bold text-white rounded-lg bg-primary hover:bg-primary-hover active:bg-primary-active transition duration-150 disabled:bg-gray-300`}
                {...rest}
            >
                { text }
            </button>
        </>
    );
}

export default CustomPrimaryButton;